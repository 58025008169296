import React, {
	useState, useEffect, createRef, useMemo
} from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { sum } from "d3";
import { rollups } from "d3-array";

import { ParentSize } from "@visx/responsive";
import Div100vh, { use100vh } from "react-div-100vh";

import ChatIcon from "@mui/icons-material/Chat";
import CommentBankIcon from "@mui/icons-material/CommentBank";
import CommentBankOutlinedIcon from "@mui/icons-material/CommentBankOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SpeedIcon from "@mui/icons-material/Speed";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GavelIcon from "@mui/icons-material/Gavel";
import ForwardIcon from "@mui/icons-material/Forward";

import { Tooltip } from "@mui/material";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import createAppSlice from "../data/slices/createAppSlice";

import SwitchSelector from "../components/filters/SwitchSelector";
import SideBar from "../components/menu/SideBar";
import TreeNodesGraph from "../components/tree/TreeNodesGraph";
import SupplyRToolTips from "../components/tooltips/SupplyRToolTips";
import MapLeaflet from "../components/map/MapLeaflet";
import SupplyRMapMarker from "../components/map/SupplyRMapMarker";
import SupplyRLayer from "../components/map/SupplyRLayer";
import ColorLegend from "../components/legends/ColorLegend";
import SupplyRSupplierDetailedCard from "../components/cards/SupplyRSupplierDetailedCard";
import SupplyRAlternativeList from "../components/cards/SupplyRAlternativeList";
import ListOfNonConformities from "../components/cards/ListOfNonConformities";
import PageHeader from "../components/header/PageHeader";
import SupplyRInfoPageHeader from "../components/header/SupplyRInfoPageHeader";
import SupplyRTermPageHeader from "../components/header/SupplyRTermPageHeader";
import NonConformitiesCard from "../components/cards/NonConformitiesCard";
import NonConformitiesCardVerkor from "../components/cards/NonConformitiesCardVerkor";
import Table from "../components/table/Table";
import Splashscreen from "../components/notifications/SplashScreen";
import SupplyRProjectScope from "../components/cards/SupplyRProjectScope";
import SupplyRRiskIndex from "../components/cards/SupplyRRiskIndex";
import ColumnGraph from "../components/graphs/ColumnGraph";
import VerkorHeader from "../components/header/VerkorHeader";
import TitleCard from "../components/cards/TitleCard";
import NCByModules from "../components/cards/NCByModules";
import ColumnGroupGraph from "../components/graphs/ColumnGroupGraph";
import Page401 from "../pages/Page401";
import PageMenuHeader from "../components/header/PageMenuHeader";
import SupplyRIndicator from "../components/graphs/SupplyRIndicator";
import { customStylesSearchSupplyR, customStylesSupplyR } from "../components/filters/SupplyRFiltersStyle";
import getSupplychainChildren from "../components/tree/SupplyRSupplychainChildren";
import VerticalGauge from "../components/graphs/VerticalGauge";
import Tabs from "../components/tabs/Tabs";
import SupplyRSupplierCardBis from "../components/cards/SupplyRTreeTooltipSupplier";
import ActionPlanScopeCard from "../components/cards/ActionPlanScopeCard";

import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, verkorInitDatasets, verkorInitRefines, filterOptions,
	fieldsTooltip, nonConformities, threeLvlNCs, listTagsNonConformities, supplyAuditsListTagsNC, clientInitParameters,
	listNonConformityQuery, VerkorCheckpoints, updateListNonConformities, domainsOptions,
	splashScreens, colorLegend, PDOColorLegend, supplyAuditsColorLegend, getMarkerColor, getPDOMarkerColor, getSupplyAuditsMarkerColor, tableStructure,
	supplyAuditsTableStructure, getAuditMode,
	langListValue, termsTabSet, infoTabSet, threeLvlNCsColorScale, nonConformitiesColorScale
} from "./configs/VerkorConfig";
import ProgressBarFlat from "../components/graphs/ProgressBarFlat";
import { requestSignIn } from "../data/slices/UserSlice";
import { openSaml } from "../auth/Authenticator";

// Create the App Slice
const appSlice = createAppSlice(
	appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	verkorInitRefines,
	verkorInitDatasets);

// Set Layout size
const mdScreens = 768;
const twoXLScreens = 1536;
const mobileMenuHeight = 60;
const filterMenuHeight = 112;
const sideBarWidth = 114;
const analysisWidth = 476;
const mobileAnalysisWidth = 348;

/**
 * Function to render the application
 */
function Verkor() {
	// Internationalization hook
	const { t, i18n } = useTranslation(appDefinition.locales);

	// Load optional URL parameters
	const urlQuery = new URLSearchParams(window.localStorage.getItem("searchParams"));
	const supplierSiteId = urlQuery.get("siteId");
	const supplierProjectName = urlQuery.get("projectName");

	// Set up state for the map to supply chain view switch
	const [mapView, setMapView] = useState(false);

	// Set up state for the map to supply chain view switch
	const [renderAnalysis, setRenderAnalysis] = useState(true);

	// Set up state for the SupplyRRiskIndex switch
	const [consolidated, setConsolidated] = useState(false);

	// Create and load the app slice in the store
	const store = useStore();

	// Load ref of content section
	const contentRef = createRef();

	// Load screen layout parameters
	const screenHeight = use100vh();
	const contentHeight = window.innerWidth > mdScreens ? (screenHeight - filterMenuHeight) : (screenHeight - mobileMenuHeight);
	const contentWidth = (document.fullscreenElement ? window.innerWidth : (Math.min(...[1900, window.innerWidth])))
		- ((window.innerWidth < mdScreens) ? 0 : (renderAnalysis ? (window.innerWidth > twoXLScreens ? (analysisWidth
			+ sideBarWidth) : (mobileAnalysisWidth + sideBarWidth)) : sideBarWidth));

	// Load the store hook
	const dispatch = useDispatch();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const subMenu = useSelector(appSlice.selectSubPage);

	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const nonConformitiesData = activeDatasets?.nonConformities?.data;
	const nonConformitiesByClosureDate = activeDatasets?.nonConformitiesByClosureDate?.data;

	// For verkor we subsequently substract the NCs with the closure date from total number of NCs,
	// this is why the total number of NCs = total - those with closure date.
	const nbNCs = nonConformitiesData?.reduce((acc, currentValue) => acc + currentValue.nbNC, 0);
	const totalNCsByClosureDate = nonConformitiesByClosureDate?.reduce((acc, currentValue) => acc + currentValue.nbNC, 0);
	const totalNCs = nbNCs - totalNCsByClosureDate;

	const {
		planedCount, inProgressCount, total, delayedCount
	} = useMemo(() => {
		const planedCount = activeDatasets?.sites?.data?.filter((item) => item.state === "PLANNED")?.length;
		const inProgressCount = activeDatasets?.sites?.data?.filter((item) => item.state === "IN PROGRESS")?.length;
		const total = activeDatasets?.sites?.data?.length;
		const delayedCount = activeDatasets?.sites?.data?.filter((item) => item.auditDateAndDelay?.assignmentDelayed === true)?.length;

		return {
			planedCount, inProgressCount, total, delayedCount
		};
	}, [activeDatasets?.sites?.data]);

	// Load client parameters
	useEffect(() => {
		// if supplied projectName, refine on it
		if (supplierProjectName) {
			dispatch(appSlice.actions.refine([{ key: "projectName", value: supplierProjectName }]));
			window.localStorage.removeItem("searchParams");
		}
		// in any case, load first available clientParameters
		if (!clientParameters) { dispatch(appSlice.fetchDataset(clientInitParameters)); }
		// and load other datasets
		if (activeRefines?.projectName) {
			dispatch(appSlice.fetchDatasets());
		}
	}, [activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (clientParameters?.projectName?.[0] && !supplierProjectName) {
			dispatch(appSlice.actions.refine([{ key: "projectName", value: clientParameters?.projectName?.[0] }]));
		}
		if (clientParameters?.supplyAudits) {
			dispatch(appSlice.actions.refine([{ key: "supplyAudits", value: clientParameters?.supplyAudits }]));
			// setMapView(true);
		}
		if (!clientParameters?.supplyAudits) dispatch(appSlice.actions.clear([{ refine: "supplyAudits" }]));
		if (clientParameters?.hideActionPlan) delete supplyAuditsListTagsNC.ncStatus;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientParameters?.projectName?.[0]]);

	// if query param with siteID then refine
	useEffect(() => {
		if (!supplierSiteId) {
			return;
		}
		dispatch(appSlice.actions.setPage("SITES"));
		dispatch(appSlice.actions.refine([{
			key: "siteId",
			value: parseInt(supplierSiteId, 10)
		}]));

		window.localStorage.removeItem("searchParams");
	}, [supplierSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch supplyChain Data to be put in the store
	useEffect(() => {
		if (activeDatasets?.nonConformities.data.length > 0) {
			const totalNonConformities = activeDatasets.nonConformities
				.data?.reduce((acc, element) => { acc.nbNC += element.nbNC; acc.nbCNC += element.nbCNC; return acc; },
					{ nbNC: 0, nbCNC: 0 });

			// Dispatch the number of non conformities in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "totalNonConformities",
					datasetData: totalNonConformities
				})
			);
		}

		// TODO: maybe there will be more than one module, it should fix the aggregation when it is the case.
		// Fetch Supply-R scores in the store - initial and if selects change only
		if (activeDatasets?.supplyChain?.data.length > 0) {
			// Fetch SupplyChain in the store
			const supplyChain = rollups(
				activeDatasets.supplyChain.data,
				(v) => ({
					resilience: sum(v, (d) => (d?.maturity ?? 0)),
					resistance: sum(v, (d) => (d?.businessContinuity ?? 0)),
					responsiveness: sum(v, (d) => (d?.safetyRegulation ?? 0))
				}),
				(d) => d.finalProduct,
				(d) => Number.parseInt(d.tierLevel, 10),
				(d) => d.targetProduct,
				(d) => d.nextSupplier,
				(d) => [d.siteLabelId, d.supplier, d.auditedCompany, d.city, d.country,
				d.product, d.geometry, d.countryRisk, d.supplierCriticality, d.siteId, d.type,
				d.region, d.tierLevel, d.tierLevelDisplay, d.client,
				d.finalProduct, d.targetProduct, d.nextSupplier, d.logisticAgent, d.tierLabel, d._id, d.siteLabelId,
				d.supplierCode, d.factoryCode, d.module, d.criticality, d.factoryBranch, d.factoryIndex, d.validated, d.siteSegmentation,
				d.areaName, d.areaNumber, d.areaOwner, d.address, d.location, d.siteName]
			);

			const rawSupplychain = {
				name: "client",
				children: supplyChain
					.sort((a, b) => a[0] > b[0] ? 1 : -1)
					.map((finalProduct, i) => ({
						name: finalProduct[0],
						children: getSupplychainChildren(finalProduct[1], "", 0, finalProduct[0], activeRefines.supplyAudits)
					}))
			};

			// Dispatch a non filtered version of the supply chain in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "supplyChainTree",
					datasetData: rawSupplychain
				})
			);
		}
	}, [clientParameters, activeDatasets?.supplyChain, activeDatasets?.nonConformities]); // eslint-disable-line react-hooks/exhaustive-deps

	// if query param with siteID then refine
	useEffect(() => {
		if (!supplierSiteId) {
			return;
		}
		dispatch(appSlice.actions.setPage("SITES"));
		dispatch(appSlice.actions.refine([{
			key: "siteId",
			value: parseInt(supplierSiteId, 10)
		}]));
	}, [supplierSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Set supplier refine in case of a selection of siteid refine
		if (activeRefines?.siteId !== undefined
			&& loadDataStatus !== "loading"
			&& activeRefines?.supplier === undefined) {
			dispatch(appSlice.actions.refine([{ key: "supplier", value: activeDatasets.sites?.data?.[0]?.supplier }]));
		}

		// Reset siteid refine section selection if another supplier is selected
		if (activeRefines?.siteId !== undefined
			&& loadDataStatus !== "loading" && activeRefines?.supplier !== undefined
			&& (activeDatasets.sites?.data?.[0]?.supplier !== activeRefines?.supplier)) {
			dispatch(appSlice.actions.refine([{ key: "siteId", value: undefined }]));
		}
	}, [loadDataStatus, activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// Reset criticality filter if not in NCs page
		if (activeRefines?.criticality !== undefined && menu !== "NCs") {
			dispatch(appSlice.actions.refine([{ key: "criticality", value: undefined }]));
		}
	}, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (loadDataStatus === "loading") {
			const defautLanguages = ["en", "fr", "zh-Hans", "zh-Hant"];
			const isDefaultLanguage = defautLanguages.includes(i18n.language);

			if (!isDefaultLanguage) {
				i18n.changeLanguage("en");
			}
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("svg-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, subMenu, activeRefines?.supplier, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	const selectedSiteResilience = activeDatasets.scores.data?.[0]?.resilienceScore?.toFixed(0);
	const selectedSiteResistance = activeDatasets.scores.data?.[0]?.resistanceScore?.toFixed(0);
	const selectedSiteResponsiveness = activeDatasets.scores.data?.[0]?.responsivenessScore?.toFixed(0);
	const currentSelectedSite = activeRefines?.siteId !== undefined ?
		activeDatasets?.sites?.data?.find((node) => node.siteId === activeRefines.siteId)
		: undefined;

	return (
		<div className="relative h-full w-full">

			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			{/*
            * Load the splashscreen if the user has not yet set it as don't remind me
            */}
			<Splashscreen appName="Verkor" notifKey="home" splashScreens={splashScreens}
				theme="supplyr" locales={appDefinition.locales} />

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full w-full bg-white" id="full-screen">

				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions.filter((e) => clientParameters?.supplyAudits ? e.label !== "My suppliers" : e.label !== "My Auditees")}
					menu={menu}
					width={sideBarWidth}
					colorMenu="#3D49AB"
					colorAccent="#6eb8d6"
					// indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbNC ?? 0 }}
					indicator={{ menu: "NCs", value: totalNCs }}
					locales={appDefinition.locales}
					langListValue={langListValue}
				/>
				<div className="flex flex-col md:pl-24 bg-supplyr_primary-bg h-full w-full max-w-full">

					<IF condition={menu !== "INFO" && menu !== "TERMs" && (menu !== "SITES" || activeRefines.supplier === undefined)}>

						<VerkorHeader
							appSlice={appSlice}
							locales={appDefinition.locales}
							appDefinition={appDefinition} />

						<div className="relative flex flex-col md:flex-row justify-between h-full w-full">

							<div className="relative flex w-full h-full rounded">
								{/*
                                * SUPPLY CHAIN & MAP PAGE
                                */}
								<IF condition={menu === "HOME"}>
									<div className="relative h-full ">
										<div id="svg-scroll"
											className={`flex rounded transition-width duration-700 ease-in-out
                                        ${mapView ? " overflow-hidden" : " overflow-x-auto overflow-y-auto no-scrollbar"}`}
											style={{
												height: contentHeight,
												maxHeight: contentHeight,
												width: contentWidth,
												maxWidth: contentWidth
											}}
										>

											<div className="absolute top-6 left-8 z-500">

												<div className="font-semibold" style={{ width: 250, height: 44 }}>
													<SwitchSelector
														onChange={(value) => setMapView(value)}
														options={
															[
																{
																	label: t("supplyChainGraph.supplyChain"),
																	value: false,
																	selectedBackgroundColor: "#fff",
																	selectedfontcolor: "#3D49AB"
																},
																{
																	label: t("supplyChainGraph.map"),
																	value: true,
																	selectedBackgroundColor: "#fff",
																	selectedfontcolor: "#3D49AB"
																}
															]
														}
														initialSelectedIndex={mapView ? 1 : 0}
														backgroundColor="#eee"
														selectedfontcolor="#818593"
														selectionIndicatorMargin={4}
													/>
												</div>

											</div>

											<div className="absolute z-500 bottom-4 left-4 flex flex-row">
												{
													mapView
													/* && <span className="mr-48">
												<MapLinksLegend />
											</span> */
												}
											</div>

											<div className="absolute z-500 bottom-4 right-8 flex flex-row">
												<ColorLegend
													locales={appDefinition.locales}
													colorLegend={clientParameters?.supplyAudits
														? supplyAuditsColorLegend
														: colorLegend}
													displayAllLabels
													colorModuleWidth="28" />
											</div>

											{/* TODO: fix dataset */}
											<ParentSize debounceTime={10}>
												{(parent) => (
													mapView
														? <MapLeaflet
															appSlice={appSlice}
															// dataset="sites"
															dataset="allSites"
															markersTree={activeDatasets.supplyChainTree?.data}
															fieldsTooltip={fieldsTooltip}
															getMarkerColor={clientParameters?.supplyAudits ? getSupplyAuditsMarkerColor : getMarkerColor}
															mapType="jawgTerrain"
															minZoom={3}
															zoom={3}
															maxClusterRadius={35}
															scrollWheelZoom={true}
															defaultColor="#d3d3d3"
															mapWidth={window.innerWidth > mdScreens ? contentWidth : window.innerWidth}
															mapHeight={parent.height}
															CustomMarker={SupplyRMapMarker}
															CustomLayer={SupplyRLayer}
															zoomSnap={0}
															wheelPxPerZoomLevel={110}
														/>
														: <TreeNodesGraph
															appDefinition={appDefinition}
															appSlice={appSlice}
															dataset="supplyChain"
															datasetTree="supplyChainTree"
															datasetFinalProduct="supplyChainActiveFinalProducts"
															ToolTips={SupplyRToolTips}
															width={window.innerWidth > mdScreens ? contentWidth : window.innerWidth}
															height={parent.height}
															refineHighlightKeys={[
																"supplier",
																"siteId",
																"tierLevel",
																"type",
																"product",
																"city",
																"region",
																"country",
																"supplierCriticality",
																"areaOwner",
																"areaName",
																"siteSegmentation",
																"location"
															]}
															refineSiteKeys={[
																"supplier",
																"siteId"
															]}
															fieldsToExport={clientParameters?.supplyAudits
																? ["projectName", "areaName", "areaNumber", "areaOwner", "siteName", "siteNumber",
																	"address", "city", "country", "region", "location", "siteSegmentation", "resilience",
																	"sumNbOpenedNc", "sumNbCnc"]
																: ["projectName", "supplier", "auditedCompany",
																	"address", "city", "country", "region",
																	"finalProduct", "product", "tierLevel", "tierLabel",
																	"resilience", "resistance", "responsiveness", "sumNbOpenedNc", "sumNbCnc"]}
															getMarkerColor={clientParameters?.supplyAudits ? getSupplyAuditsMarkerColor : getMarkerColor}
															margin={{
																top: 10, left: 0, right: 0, bottom: 300
															}}
															subTreeMargin={{
																top: 130, left: -130, right: 0, bottom: 0
															}}
															consolidated={consolidated}
															displayTooltip
															TreeTooltip={SupplyRSupplierCardBis}
															titleCase={!activeRefines?.supplyAudits}
														/>
												)}
											</ParentSize>
										</div>
									</div>
								</IF>

								<IF condition={menu === "NCs" || (menu === "SITES" && activeRefines.supplier === undefined)}>
									<div id="svg-scroll"
										className={"flex rounded w-full overflow-y-auto"}
										style={{ height: contentHeight, maxHeight: contentHeight }}
									>
										{/*
                                        * NON CONFORMITIES PAGE
                                        */}
										<IF condition={menu === "NCs"}>
											<div className="flex flex-col px-8 w-full 2xl:px-12" ref={contentRef}>
												<div className="flex justify-between mt-1">
													<PageHeader
														page="NCsPage"
														Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
														locales={appDefinition.locales}
													>
													</PageHeader>
												</div>
												<div className="grid grid-cols-1 grid-flow-row gap-y-1 pb-16
                                                            md:grid-cols-4 md:gap-x-8 ">
													<div className="flex flex-col space-y-4 w-full  md:col-span-3">
														<IF condition={!clientParameters?.hideActionPlan}>
															<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4">
																<NonConformitiesCardVerkor
																	nonConformities={clientParameters?.threeLevelNC
																		? threeLvlNCs
																		: nonConformities} theme="supplyr"
																	appSlice={appSlice} dataset="nonConformities"
																	datasetNCMediumLow="nonConformitiesMediumLowDistribution"
																	datasetListCriticalities={clientParameters?.threeLevelNC
																		? "listCriticalityLevels"
																		: "listCriticalities"}
																	criticalityKey={clientParameters?.threeLevelNC
																		? "criticalityLevel"
																		: "criticality"}
																	locales={appDefinition.locales}
																	colorScale={clientParameters?.threeLevelNC
																		? threeLvlNCsColorScale
																		: nonConformitiesColorScale}
																	showCriticalitySplit={false} />
															</div>
														</IF>
														<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 ">
															<ListOfNonConformities
																appSlice={appSlice}
																appNCConfig={{
																	nonConformities,
																	listNonConformityQuery,
																	nonConformityDataset: VerkorCheckpoints,
																	updateListNonConformities,
																	customStylesSubFilter: customStylesSupplyR,
																	listTagsNonConformities: (clientParameters?.supplyAudits
																		? supplyAuditsListTagsNC
																		: listTagsNonConformities),
																	filterOptions: filterOptions.filter(
																		(option) => option.label === "Section"
																			|| (clientParameters?.threeLevelNC
																				? option.label === "CriticalityLevel" : option.label === "Criticality")
																			|| option.label === "Answer"
																			|| (!clientParameters?.hideActionPlan && option.label === "NCStatus")
																			|| option.label === "Plan Item Delayed"
																	)
																}}
																forceObservation
																observations={clientParameters?.scoringBySection
																	? [
																		{
																			locale: "auditorComment",
																			accessorResult: (d) => d.auditorResult,
																			accessorObs: (d) => d.auditorComment,
																			accessorCriticality: (d) => d.criticalityLevel,
																			picto: CommentBankIcon
																		}, {
																			locale: "actionPlanned",
																			accessorActionPlanned: (d) => d.actionPlanned,
																			picto: ForwardIcon,
																			pictoColor: ""
																		}
																	]
																	: [
																		{
																			locale: "supplierComment",
																			accessorResult: (d) => d.supplierResult,
																			accessorObs: (d) => d.supplierComment,
																			picto: ChatIcon
																		},
																		{
																			locale: "auditorComment",
																			accessorResult: (d) => d.auditorResult,
																			accessorObs: (d) => d.auditorComment,
																			picto: CommentBankIcon
																		}, {
																			locale: "actionPlanned",
																			accessorActionPlanned: (d) => d.actionPlanned,
																			picto: ForwardIcon,
																			pictoColor: ""
																		}
																	]}
																totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
																contentRef={contentRef}
																dataset="listNonConformities"
																locales={appDefinition.locales}
																themePalette="supplyr"
															/>
														</div>
													</div>
													<div className="hidden md:flex flex-col space-y-4 w-full">
														<div className="flex flex-col w-full alphaBlur rounded-lg px-4 xl:px-6 py-4 space-y-8">
															<NonConformitiesCardVerkor
																appSlice={appSlice}
																nonConformities={clientParameters?.threeLevelNC
																	? threeLvlNCs
																	: nonConformities}
																dataset="nonConformities"
																synthesis
																locales={appDefinition.locales}
																showNonConformity={false}
																datasetListCriticalities={clientParameters?.threeLevelNC
																	? "listCriticalityLevels"
																	: "listCriticalities"}
																criticalityKey={clientParameters?.threeLevelNC
																	? "criticalityLevel"
																	: "criticality"}
															/>
														</div>
														<div className="flex flex-col w-full alphaBlur rounded-lg px-4 xl:px-6 py-4 space-y-8">
															<NonConformitiesCardVerkor
																nonConformities={clientParameters?.threeLevelNC
																	? threeLvlNCs
																	: nonConformities} theme="supplyr"
																appSlice={appSlice} dataset="nonConformities"
																datasetNCMediumLow="nonConformitiesMediumLowDistribution"
																noMonthlyAnalysis
																datasetListCriticalities={clientParameters?.threeLevelNC
																	? "listCriticalityLevels"
																	: "listCriticalities"}
																criticalityKey={clientParameters?.threeLevelNC
																	? "criticalityLevel"
																	: "criticality"}
																locales={appDefinition.locales}
																showCriticalitySplit={true} />
														</div>
														{activeDatasets?.listModules?.data?.length > 1
															&& <div className="flex flex-col w-full alphaBlur rounded-lg px-4 xl:px-6 py-4 space-y-8">
																<TitleCard titleLocal="NCsPage.byModules.title"
																	subTitleLocal="" small theme={appDefinition.theme}
																	infoLocal="NCsPage.byModules.info" locales={appDefinition.locales} />
																<NCByModules appSlice={appSlice} dataset="nonConformitiesByModules"
																	domainsOptions={domainsOptions} noPicto locales={appDefinition.locales} />
															</div>}
													</div>
												</div>
											</div>
										</IF>

										{/*
                                        * SITES TABLE PAGE
                                        */}
										<IF condition={menu === "SITES" && activeRefines.supplier === undefined}>
											<div className="flex flex-col px-8 w-full 2xl:px-12" ref={contentRef}>
												<div className="flex justify-between">
													<PageHeader
														page={`${clientParameters?.supplyAudits ? "listAuditeesPage" : "listSuppliersPage"}`}
														Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
														locales={appDefinition.locales}
													/>
												</div>
												<div className="flex flex-col  space-y-4 w-full md:col-span-2">
													<div className="flex flex-col w-full  alphaBlur rounded-lg px-6 py-4">
														<Table
															appSlice={appSlice}
															dataset="sites"
															structureTable={activeRefines.supplyAudits
																? supplyAuditsTableStructure
																: tableStructure}
															refineKeys={(row) => [
																{ key: "supplier", value: row.original.supplier },
																{ key: "siteId", value: row.original.siteId }
															]}
															sortByRefineFields={[
																{ id: "auditDate", desc: true },
																{ id: "resilience", desc: true },
																{ id: "resilienceScores", desc: true }]}
															themePalette="supplyr"
															locales={appDefinition.locales}
															renderTableFilter
															filterOptions={filterOptions.filter((option) => option.label === "Audit Status"
																|| option.label === "Delayed")}
															customStylesSubFilter={customStylesSupplyR}
															enableDispatch={(row) => {
																// If there are no scores -> show tooltip and do not redirect to the site page.
																if (activeRefines.module && !row.original?.resilienceScores) {
																	return false;
																}
																if (!activeRefines.module && !row.original?.resilience) {
																	return false;
																}
																return true;
															}}
														/>
													</div>
												</div>
											</div>
										</IF>

									</div>
								</IF>
							</div>

							{/*
                            * Supply-R Analysis section
                            */}
							<IF condition={menu === "HOME"}>

								<div className={`absolute md:right-0 bottom-0 flex flex-col md:flex-row md:justify-end 
                                transition-all duration-700 ease-in-out flex-shrink-0 w-0 z-500
                                 ${renderAnalysis ? "max-h-full pt-20 md:pt-0 w-full  md:w-96 2xl:w-112" : " w-full md:w-4 h-24  md:h-full"}`}
									style={{ height: contentHeight, maxHeight: contentHeight }}>

									<div className="absolute hidden md:flex h-full justify-center md:justify-start
                                    items-start md:items-center -left-3  z-2xl">
										<button className="flex flex-shrink-0 items-center justify-center h-6 w-6 bg-white border rounded-full"
											onClick={() => {
												setRenderAnalysis(!renderAnalysis);
											}}>
											{renderAnalysis ?
												<KeyboardArrowRightIcon fontSize="inherit" className=" text-supplyr_primary-accent" />
												:
												<KeyboardArrowLeftIcon fontSize="inherit" className="text-supplyr_primary-menu" />

											}
										</button>
									</div>

									<div className={`relative flex flex-col gap-y-6 border-t md:border-0
                                                       rounded-t-3xl md:rounded-tr-none md:rounded-tl-3xl
                                                       bg-white pt-2 md:pt-4  shadow-leftxl overflow-y-auto
                                                       transition-all duration-700 ease-in-out
                                                       ${renderAnalysis ? "w-full h-full px-4 pb-20 " : " md:w-4"}`}>

										<div className="flex md:hidden w-full h-10 justify-center cursor-pointer"
											onClick={(event) => setRenderAnalysis(!renderAnalysis)}>
											<div className="w-24 h-1 bg-gray-600 rounded-full animate-bounce" />
										</div>

										<IF condition={renderAnalysis}>
											<IF condition={activeRefines.supplier === undefined}>
												<SupplyRProjectScope
													appSlice={appSlice}
													dataset="criticalityDistribution"
													total={total}
													totalCovered={activeDatasets.sitesDistribution?.data?.length}
													planedCount={planedCount}
													inProgressCount={inProgressCount}
													counterPills={[
														{
															id: "assessed",
															counter: activeDatasets.sitesDistribution?.data?.length,
															color: "bg-scope-assessment"
														},
														{
															id: "planned",
															counter: planedCount,
															color: "bg-scope-planned"
														},
														{
															id: "in progress",
															counter: inProgressCount,
															color: "bg-scope-inProgress"
														},
														{
															id: "delayed",
															counter: delayedCount,
															color: "bg-scope-delayed"
														}
													]}
													locales={appDefinition.locales}
													totalLocaleKey={clientParameters?.supplyAudits ? "auditees" : "factory"}
													refineOnAuditStatus
												>
												</SupplyRProjectScope>
												<SupplyRProjectScope
													appSlice={appSlice}
													title="Global scope by Overall result"
													titleLocale="analysis.overallScope"
													dataset="overallResultScope"
													scopeList="overallResultScope"
													categoriesListDataset="listGradingsLabels"
													categoriesKey="gradingsLabel"
													categoriesLocale="analysis.scope.gradings"
													totalLocaleKey="audits"
													refineOnClick={false}
													total={activeDatasets.overallResultScope?.data[0]?.totalAudits}
													displayInner={false}
													displayPills={false}
													locales={appDefinition.locales}
												>
												</SupplyRProjectScope>
											</IF>

											<IF condition={activeRefines.supplier !== undefined}>
												<SupplyRSupplierDetailedCard appSlice={appSlice} dataset="sites" />
											</IF>

											<IF condition={activeDatasets.scores?.data?.[0]?.resilienceScore
												&& activeDatasets.scores?.data?.[0]?.resilienceScore !== 0}>
												<div className="flex items-center justify-between ">
													<p className="text-supplyr_primary-menu font-bold text-lg">{t("analysis.switch.seeResultsBy")}</p>
													<div className="font-semibold" style={{ width: 220, height: 44 }}>
														<SwitchSelector
															onChange={(newValue) => dispatch(appSlice.actions.setSubPage(newValue))}
															options={
																[
																	{
																		label: t("analysis.switch.KPIs"),
																		value: "KPIs",
																		selectedBackgroundColor: "#fff",
																		selectedfontcolor: "#3D49AB"
																	},
																	{
																		label: t("analysis.switch.Distribution"),
																		value: "DISTRIB",
																		selectedBackgroundColor: "#fff",
																		selectedfontcolor: "#3D49AB"
																	}
																]
															}
															initialSelectedIndex={subMenu === "DISTRIB" ? 1 : 0}
															backgroundColor={"#eee"}
															fontColor={"#818593"}
															selectionIndicatorMargin={4}
														/>
													</div>
												</div>

												{/*
                                                * KPIs ANALYSIS
                                                */}
												<IF condition={subMenu !== "DISTRIB"}>
													<div className={`flex flex-col ${(activeRefines.module !== undefined) && "gap-y-4"}`}>
														<IF condition={activeRefines.module === undefined}>
															<TitleCard titleLocal="analysis.globalriskindex.title" subTitleLocal=""
																small infoLocal="analysis.globalriskindex.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</IF>
														<IF condition={activeRefines.module !== undefined}>
															<TitleCard titleLocal="analysis.moduleriskindex.title" subTitleLocal=""
																picto={getAuditMode(activeDatasets.modulesDistribution?.data?.[0]?.auditType,
																	activeDatasets.modulesDistribution?.data?.[0]?.auditMode)}
																prefix={`module.${activeRefines.module}`}
																small infoLocal="analysis.moduleriskindex.desc"
																theme={appDefinition.theme} locales={appDefinition.locales} />
														</IF>
														<div className="px-4">
															<SupplyRRiskIndex appSlice={appSlice}
																dataset={activeRefines.module === undefined ? "scores" : "scoresModule"}
																locales={appDefinition.locales} consolidated={consolidated}
																setConsolidated={setConsolidated} resilienceText="maturity"
																resistanceText="businessContinuity" responsivenessText="safetyRegulation"
																domainsOptions={domainsOptions}
																getMaturityColor={clientParameters?.supplyAudits
																	? getSupplyAuditsMarkerColor : getMarkerColor}
																noAdditionalProgressBars={activeRefines.supplyAudits}
															/>
														</div>
													</div>

													<IF condition={activeRefines.module === undefined && activeDatasets.listModules.data.length > 1}>
														<TitleCard titleLocal="analysis.splitPerModule.title" subTitleLocal=""
															small infoLocal="analysis.splitPerModule.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
														<div>
															<ParentSize>
																{(parent) => (
																	<ColumnGraph
																		appSlice={appSlice}
																		dataset={"modulesDistribution"}
																		color={"#1876bd"}
																		strokeWidth={8}
																		width={parent.width}
																		margin={{
																			top: 25,
																			left: 10,
																			right: 30,
																			bottom: 0
																		}}
																		getScore={(d) => d.value}
																		getModule={(d) => d.module}
																		getPicto={(d) => getAuditMode(d.auditType, d.auditMode)}
																		refineKeys={["module"]}
																		locales={appDefinition.locales}
																		prefix="module"
																	/>
																)}
															</ParentSize>
														</div>
													</IF>

													<IF condition={activeRefines.module !== undefined || activeDatasets.listModules.data.length === 1}>
														<TitleCard titleLocal="analysis.splitPerSection.title" subTitleLocal=""
															small infoLocal="analysis.splitPerSection.desc"
															theme={appDefinition.theme} locales={appDefinition.locales} />
														<div>
															<ParentSize>
																{(parent) => (
																	<ColumnGraph
																		appSlice={appSlice}
																		dataset={"sectionsDistribution"}
																		sortData={(a, b) => Number.isNaN(parseInt(a.sectionIndex, 10)) ?
																			((a.section < b.section) ? 1 : -1)
																			: ((parseInt(a.sectionIndex, 10) < parseInt(b.sectionIndex, 10)) ? 1 : -1)}
																		color={"#1876bd"}
																		strokeWidth={8}
																		width={parent.width}
																		margin={{
																			top: 25,
																			left: 10,
																			right: 30,
																			bottom: 0
																		}}
																		getScore={(d) => d.value}
																		getModule={(d) => d.section}
																		getColor={clientParameters?.supplyAudits && getSupplyAuditsMarkerColor}
																		locales={appDefinition.locales}
																	/>
																)}
															</ParentSize>
														</div>
													</IF>

													<NonConformitiesCard nonConformities={clientParameters?.threeLevelNC
														? threeLvlNCs
														: nonConformities} theme="supplyr"
														appSlice={appSlice} dataset="nonConformities"
														datasetNCMediumLow="nonConformitiesMediumLowDistribution" noMonthlyAnalysis
														datasetListCriticalities={clientParameters?.threeLevelNC
															? "listCriticalityLevels"
															: "listCriticalities"}
														criticalityKey={clientParameters?.threeLevelNC
															? "criticalityLevel"
															: "criticality"}
														locales={appDefinition.locales} />

												</IF>

												{/*
                                                * DISTRIBUTION ANALYSIS
                                                */}
												<IF condition={subMenu === "DISTRIB" && activeRefines.supplier === undefined}>

													<TitleCard
														titleLocal={`analysis.${clientParameters?.supplyAudits
															? "benchmark.areaName"
															: "finalProduct.benchmarkAll"
															}.title`}
														subTitleLocal=""
														small
														infoLocal={`analysis.${clientParameters?.supplyAudits
															? "benchmark.areaName"
															: "finalProduct.benchmarkAll"
															}.desc`}
														theme={appDefinition.theme}
														locales={appDefinition.locales}
													/>
													<div>
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={"finalProductDistribution"}
																	color={"#1876bd"}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.value}
																	getModule={(d) => d.finalProduct}
																	refineKeys={["finalProduct"]}
																/>
															)}
														</ParentSize>
													</div>

													<TitleCard titleLocal={`analysis.benchmark.${clientParameters?.supplyAudits
														? "areaOwner" : "supplierAll"}.title`} subTitleLocal=""
														small infoLocal={`analysis.benchmark.${clientParameters?.supplyAudits
															? "areaOwner" : "supplierAll"}.desc`}
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div>
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={`${clientParameters?.supplyAudits
																		? "areaOwnersDistribution" : "sitesDistribution"}`}
																	color={"#1876bd"}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.resilienceScore}
																	getModule={(d) => clientParameters?.supplyAudits ? d.areaOwner : d.supplier}
																	refineKeys={
																		[
																			clientParameters?.supplyAudits ? "areaOwner" : "supplier"
																		]}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
													</div>

													<TitleCard titleLocal={`analysis.benchmark.${clientParameters?.supplyAudits
														? "auditee"
														: "factoryAll"}.title`} subTitleLocal=""
														small infoLocal={`analysis.benchmark.${clientParameters?.supplyAudits
															? "auditee"
															: "factoryAll"}.desc`}
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div>
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={"sitesDistribution"}
																	color={"#1876bd"}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.resilienceScore}
																	getModule={(d) => d.auditedCompany}
																	refineKeys={["supplier", "siteId"]}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
													</div>
												</IF>

												{/*
                                                * DISTRIBUTION BENCHMARK ANALYSIS
                                                */}
												<IF condition={subMenu === "DISTRIB" && activeRefines.supplier !== undefined}>
													<TitleCard
														// alysis.finalProduct.benchmarkSelected.title"
														titleLocal={`analysis.${clientParameters?.supplyAudits
															? "benchmark.areaOwnerSelected"
															: "finalProduct.benchmarkSelected"
															}.title`}
														subTitleLocal=""
														small infoLocal="analysis.finalProduct.benchmarkSelected.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />
													<div className="flex flex-col">
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGroupGraph
																	appSlice={appSlice}
																	dataset1={"finalProductDistribution"}
																	dataset2={"finalProductBenchmark"}
																	color={"#1876bd"}
																	width={parent.width}
																	getAbs={(d) => d.value}
																	getOrd={(d) => d.finalProduct}
																	refineKeys={["finalProduct"]}
																	locales={appDefinition.locales}
																	prefix="product"
																/>
															)}
														</ParentSize>
														<p className="flex items-center text-medium gap-x-6">
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-supplyr_primary-accent rounded-full" />
																<p>{t(`analysis.legend.${clientParameters?.supplyAudits
																	? "selectedAreaOwner"
																	: "selectedFactory"}`)}</p>
															</div>
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-gray-500 rounded-full" />
																<p>{t(`analysis.legend.${clientParameters?.supplyAudits
																	? "allAreaOwners"
																	: "allFactories"}`)}</p>
															</div>
														</p>
													</div>

													<TitleCard titleLocal={`analysis.benchmark.${clientParameters?.supplyAudits
														? "auditeeSelected" : "supplierSelected"}.title`} subTitleLocal=""
														small infoLocal="analysis.benchmark.supplierSelected.desc"
														theme={appDefinition.theme} locales={appDefinition.locales} />

													<div className="flex flex-col">
														<ParentSize className="graph-container" debounceTime={10}>
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={"sitesBenchmark"}
																	color={"#1876bd"}
																	mapData={(d) => {
																		const element = { ...d };
																		element.inactive = !(element.supplier === activeRefines.supplier &&
																			((activeRefines.siteId === undefined)
																				|| (element.siteId === activeRefines.siteId)));
																		return element;
																	}}
																	strokeWidth={8}
																	width={parent.width}
																	margin={{
																		top: 25,
																		left: 10,
																		right: 30,
																		bottom: 0
																	}}
																	sortScoreDimension
																	getScore={(d) => d.resilienceScore}
																	getModule={(d) => d.auditedCompany}
																	refineKeys={["supplier"]}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>
														<p className="flex items-center text-medium gap-x-6">
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-supplyr_primary-menu rounded-full" />
																<p>{t(`analysis.legend.${clientParameters?.supplyAudits
																	? "selectedAuditee"
																	: "selectedFactory"}`)}</p>
															</div>
															<div className="flex items-center gap-x-2">
																<p className="h-2 w-6 bg-gray-500 rounded-full" />
																<p>{t(`analysis.legend.${clientParameters?.supplyAudits
																	? "allAuditees"
																	: "allFactories"}`)}</p>
															</div>
														</p>
													</div>
												</IF>
											</IF>
										</IF>
									</div>
								</div>
							</IF>
						</div>
					</IF>

					{/*
                    * SITE SELECTED PAGE
                    */}
					<IF condition={menu === "SITES"
						&& (activeRefines.supplier !== undefined)}>
						<Div100vh className="overflow-y-auto">
							<PageMenuHeader
								appSlice={appSlice}
								Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
								filterOption={filterOptions.find((option) => option.label === "Modules")}
								customStylesSearch={customStylesSearchSupplyR}
								themePalette="supplyr"
								logo={appDefinition.logo}
								locales={appDefinition.locales}
							/>

							<div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row
                                    -mt-36 mx-4 md:mx-10 gap-y-6 gap-x-4 md:gap-x-6 ">
								<div className="md:ml-10 md:mr-20 z-10 md:col-span-1">
									<SupplyRSupplierDetailedCard appSlice={appSlice} dataset="sites" />
								</div>
								<div className="relative overflow-hidden shadow-md rounded-xl h-68 bg-white z-10 md:col-span-1">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												getMarkerColor={clientParameters?.supplyAudits ? getSupplyAuditsMarkerColor : getMarkerColor}
												markersTree={activeDatasets?.supplyChainTree?.data}
												fieldsTooltip={fieldsTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												zoom={2}
												scrollWheelZoom={true}
												defaultColor="#d3d3d3"
												mapWidth={window.innerWidth > mdScreens ? parent.width : window.innerWidth}
												mapHeight={parent.height}
												CustomMarker={SupplyRMapMarker}
												zoomSnap={0}
												wheelPxPerZoomLevel={110}
											/>
										)}
									</ParentSize>
									{activeRefines.siteId !== undefined && <div
										className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
										<LocationOnIcon fontSize="small" />
										<p className="line-clamp-1">
											{activeDatasets.sites?.data?.[0]?.country}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.city}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.address}
										</p>
									</div>}
								</div>

								<IF condition={clientParameters?.allowRequestAudit && (!activeDatasets.scores?.data?.[0]?.resilienceScore
									|| activeDatasets.scores?.data?.[0]?.resilienceScore === 0)}>
									<div className="w-full flex justify-between items-start md:col-span-2">
										<a target="_blank" rel="noopener noreferrer"
											href={(`mailto:
                                            supplyr-${activeRefines.projectName}-${activeDatasets.sites?.data?.[0]?.country}@bureauveritas.com
                                            ?subject=${`${clientParameters?.client} - ${activeRefines.supplier} - ${t("sitePage.requestAudit")}`}`)
												.replace(/\s/g, "")}
											className="flex items-center px-10 py-3 font-semibold rounded-xl
                                                       bg-white shadow-lg text-supplyr_primary-menu text-sm">
											{t("sitePage.requestAudit")}
										</a>
									</div>
								</IF>

								<IF condition={subMenu !== "ALTERNATIVES"
									&& (activeDatasets.scores?.data?.[0]?.resilienceScore && activeDatasets.scores?.data?.[0]?.resilienceScore !== 0)}>
									<div className="w-full flex flex-col md:flex-row justify-between gap-x-6 items-start md:col-span-2">

										<TitleCard titleLocal="sitePage.discover.title" subTitleLocal="sitePage.discover.subTitle"
											infoLocal="sitePage.discover.info" small theme={appDefinition.theme} locales={appDefinition.locales} />

										{clientParameters?.allowRequestAudit && <a target="_blank" rel="noopener noreferrer"
											href={(`mailto:
                                            supplyr-${activeRefines.projectName}-${activeDatasets.sites?.data?.[0]?.country}@bureauveritas.com
                                            ?subject=${`${clientParameters?.client} - ${activeRefines.supplier} - ${t("sitePage.refreshAudit")}`}`)
												.replace(/\s/g, "")}
											className="flex flex-shrink-0 items-center px-10 py-3 font-semibold rounded-xl
                                                    bg-white shadow-lg text-supplyr_primary-menu text-sm order-first mb-6 md-mb-0 md:order-last">
											{t("sitePage.refreshAudit")}
										</a>}
									</div>
									<div className="relative flex  flex-wrap gap-1 md:gap-3 md:col-span-2 -mt-6 2xl:gap-5">
										<IF condition={activeRefines.module === undefined && activeDatasets.listModules.data.length > 1}>
											<div className="flex flex-col items-center px-3 py-4 gap-y-2 bg-white rounded-xl">
												<VerticalGauge score={selectedSiteResilience ?? 0} height={120} width={102}>
													<div className="-mt-1 flex flex-col items-center justify-center gap-y-2 text-white font-light h-full">
														<SpeedIcon className="text-xl text-white" />
														<p className="text-3xl leading-4 mt-1">
															{(`${selectedSiteResilience}%`) ?? "n/a"}
														</p>
														<p className="text-medium">{t("sitePage.discover.Resilience")}</p>
													</div>

												</VerticalGauge>
												{selectedSiteResistance !== "0" && selectedSiteResponsiveness !== "0"
													&& <div className="flex flex-col w-full">
														<div className="flex items-center w-full justify-between gap-x-1">
															<VerticalAlignCenterIcon style={{ fontSize: 14 }} className="mx-1" />
															<ProgressBarFlat
																strokeColor={getMarkerColor(selectedSiteResistance)}
																endPoint={selectedSiteResistance}
																width={74}
																height={9} />
															<p className="text-medium">{(`${selectedSiteResistance}%`) ?? "n/a"}</p>
														</div>
														<div className="flex items-center w-full justify-between gap-x-1">
															<TimelapseIcon style={{ fontSize: 14 }} className="mx-1" />
															<ProgressBarFlat
																strokeColor={getMarkerColor(selectedSiteResponsiveness)}
																endPoint={selectedSiteResponsiveness}
																width={74}
																height={9} />
															<p className="text-medium">{(`${selectedSiteResponsiveness}%`) ?? "n/a"}</p>
														</div>
													</div>}
											</div>
											<div className="hidden md:inline border-r border-gray-300" />
										</IF>
										{activeDatasets?.modulesDistribution?.data?.map((dimension, i) => {
											const moduleScore = dimension?.value?.toFixed(0);
											const resistanceScore = dimension?.resistance?.toFixed(0);
											const responsivenessScore = dimension?.responsiveness?.toFixed(0);
											const moduleOption = domainsOptions[dimension.module];
											const moduleReports = dimension.reports;

											return (
												<div key={`indicator ${i}`}
													className="flex flex-col items-center px-2 md:px-4 py-4 gap-y-2 bg-white rounded-xl">
													<SupplyRIndicator
														score={moduleScore}
														logo={moduleOption?.logo}
														locale={moduleOption?.locale}
														locales={appDefinition.locales} />
													{selectedSiteResistance !== "0" && selectedSiteResponsiveness !== "0"
														&& <div className="flex flex-col w-full">
															<Tooltip title={t("sitePage.indicators.resistance")} followCursor={true}>
																<div className="flex items-center w-full justify-between gap-x-1">
																	<VerticalAlignCenterIcon style={{ fontSize: 14 }} className="mx-1" />
																	<ProgressBarFlat
																		strokeColor={getMarkerColor(resistanceScore)}
																		endPoint={resistanceScore}
																		width={74}
																		height={9} />
																	<p className="text-medium">{(`${resistanceScore}%`) ?? "n/a"}</p>
																</div>
															</Tooltip>
															<Tooltip title={t("sitePage.indicators.responsiveness")} followCursor={true}>
																<div className="flex items-center w-full justify-between gap-x-1">
																	<TimelapseIcon style={{ fontSize: 14 }} className="mx-1" />
																	<ProgressBarFlat
																		strokeColor={getMarkerColor(responsivenessScore)}
																		endPoint={responsivenessScore}
																		width={74}
																		height={9} />
																	<p className="text-medium">{(`${responsivenessScore}%`) ?? "n/a"}</p>
																</div>
															</Tooltip>
														</div>}
													{
														currentSelectedSite !== undefined && currentSelectedSite.gradingsLabel !== undefined &&
														<span className="text-xs">{currentSelectedSite.gradingsLabel}</span>
													}

													{clientParameters?.allowReportLink && moduleReports.length === 1 &&
														<div className="flex justify-center items-center w-full">
															<a target="_blank" rel="noopener noreferrer"
																href={`https://odsdatahub.bureauveritas.com/reports/${dimension?.reports?.[0]}`}
																className="flex justify-center items-center space-x-1
                                                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                                                    hover:border-gray-400" >
																<InsertDriveFileIcon style={{ fontSize: "1rem" }} />
																<p className="flex flex-shrink-0">{t("NCsPage.listNCs.nonConformity.viewReports")}</p>
															</a>
														</div>}
												</div>
											);
										})}
									</div>

									<div className="flex flex-col w-full">

										<TitleCard titleLocal="sitePage.detailedScore.title" subTitleLocal="sitePage.detailedScore.subTitle" small
											infoLocal="sitePage.detailedScore.info" theme={appDefinition.theme} locales={appDefinition.locales} />

										<div className="flex flex-col space-y-6 w-full ">
											{activeDatasets.modulesDistribution?.data?.map((dimension, i) => {
												const moduleScore = dimension?.value?.toFixed(0);
												const moduleOption = domainsOptions[dimension.module];
												const auditMode = getAuditMode(dimension.auditType, dimension.auditMode);
												const Picto = auditMode.picto;

												return ((moduleScore > 0) &&
													<div key={`sectionScore ${i}`}
														className="flex flex-col space-y-2 rounded-xl alphaBlur w-full px-6 pt-4 pb-4">
														<div className="flex items-center gap-x-2 text-lg">
															<Tooltip title={t(auditMode.locale)} placement="top">
																<Picto fontSize="medium" />
															</Tooltip>
															<p>{t(`domains.${moduleOption?.locale}.name`)}</p>
														</div>
														<ParentSize >
															{(parent) => (
																<ColumnGraph
																	appSlice={appSlice}
																	dataset={"sectionsDistribution"}
																	filterData={(d) => d.module === dimension.module}
																	sortData={
																		(a, b) => Number.isNaN(parseInt(a.sectionIndex, 10)) ?
																			((a.section < b.section) ? 1 : -1) :
																			((parseInt(a.sectionIndex, 10) < parseInt(b.sectionIndex, 10)) ? 1 : -1)
																	}
																	color={"#1876bd"}
																	width={parent.width}
																	strokeWidth={8}
																	gradientStartBarColor="#CBDA72"
																	gradientEndBarColor="#6E8F57"
																	nonConformities
																	getScore={(d) => d.value}
																	getModule={(d) => d.section}
																	getColor={clientParameters?.supplyAudits && getSupplyAuditsMarkerColor}
																	locales={appDefinition.locales}
																/>
															)}
														</ParentSize>

														<IF condition={clientParameters.scoringBySection}>
															<div className="flex items-center gap-x-2 mb-2 text-lg">
																<BallotOutlinedIcon fontSize="medium" className="text-blue" />
																<p>Overall Comment</p>
															</div>
															{activeDatasets.overallComment.data.map((section, i) => (
																<div key={`${section}-${i}`} >
																	{/* NOTE :
																	HERE I AM USING dangerouslySetInnerHTML because html was being provided by the form but it may be an error.
																	I left in the comments an other way to display the overall comment just in case */}
																	{section.overallComment
																		? <div dangerouslySetInnerHTML={{ __html: section.overallComment }}></div>
																		: <p className="italic font-light">
																			No overall comments available for this report
																		</p>}
																	{/* <div className="text-sm ml-2 mt-1 mb-3 flex items-center leading-none">
																		<CommentBankOutlinedIcon
																			fontSize="xs" className="text-supplyr_primary-accent mr-2"/>
																		<span>{section.overallComment}</span>
																	</div> */}
																</div>))}
														</IF>
														<IF condition={!clientParameters.scoringBySection}>
															<div className="flex items-center gap-x-2 mb-2 text-lg">
																<BallotOutlinedIcon fontSize="medium" className="text-blue" />
																<p>Section Comments</p>
															</div>
															{activeDatasets.sectionComments.data.map((section, i) => (
																<div key={`${section}-${i}`} >
																	<h6 className="text-sm font-semibold">
																		{section.section1Label}
																	</h6>
																	<div className="text-sm ml-2 mt-1 mb-3 flex items-center leading-none">
																		<CommentBankOutlinedIcon
																			fontSize="xs" className="text-supplyr_primary-accent mr-2" />
																		<span>{section.sectionComment}</span>
																	</div>
																</div>))}
														</IF>
													</div>
												);
											})}
										</div>
									</div>

									<div className="flex flex-col w-full">

										<TitleCard titleLocal="sitePage.nonConformity.title" subTitleLocal="sitePage.nonConformity.subTitle" small
											infoLocal="sitePage.nonConformity.info" theme={appDefinition.theme} locales={appDefinition.locales} />

										<div className={`${activeDatasets?.nonConformities?.length > 0 && "rounded-xl alphaBlur w-full px-6 py-4"}`}>
											<NonConformitiesCard
												appSlice={appSlice}
												dataset="nonConformities"
												datasetNCMediumLow="nonConformitiesMediumLowDistribution"
												theme="supplyr"
												nonConformities={clientParameters?.threeLevelNC
													? threeLvlNCs
													: nonConformities}
												synthesis locales={appDefinition.locales}
												callToAction={!clientParameters?.hideActionPlan && activeDatasets?.getActionPlan?.data.length > 0}
												ctaLink={activeDatasets?.getActionPlan?.data[0]?.urlActionPlan}
												ctaContent={t("NCsPage.actionPlan.fillActionPlan")}
												ctaAssignementIcon
												colorScale={clientParameters?.threeLevelNC
													? threeLvlNCsColorScale
													: nonConformitiesColorScale}
											/>
										</div>
										{!clientParameters?.hideActionPlan && activeDatasets?.getActionPlan?.data[0]?.urlActionPlan &&
											<div className="mt-4 mb-2">
												<ActionPlanScopeCard
													appSlice={appSlice}
													appDefinition={appDefinition}
													datasets={["sites", "actionPlanDelayCount", "actionPlanStatusDistribPerSites"]}
													dataset="actionPlanStatusDistribPerSites"
													refineOnClick={true}
													clientParams={clientParameters}
													theme={"supplyr"} />
											</div>
										}
										{!clientParameters?.hideActionPlan && <div className="rounded-xl alphaBlur w-full px-6 py-4 mt-6">
											<NonConformitiesCardVerkor
												nonConformities={clientParameters?.threeLevelNC
													? threeLvlNCs
													: nonConformities} theme="supplyr"
												appSlice={appSlice} dataset="nonConformities"
												datasetNCMediumLow="nonConformitiesMediumLowDistribution"
												datasetListCriticalities={clientParameters?.threeLevelNC
													? "listCriticalityLevels"
													: "listCriticalities"}
												criticalityKey={clientParameters?.threeLevelNC
													? "criticalityLevel"
													: "criticality"}
												locales={appDefinition.locales}
												colorScale={clientParameters?.threeLevelNC
													? threeLvlNCsColorScale
													: nonConformitiesColorScale} />
										</div>}
										<div className="rounded-xl alphaBlur w-full px-6 py-4 mt-6">
											<ListOfNonConformities
												appSlice={appSlice}
												appNCConfig={{
													nonConformities,
													listNonConformityQuery,
													nonConformityDataset: VerkorCheckpoints,
													updateListNonConformities,
													customStylesSubFilter: customStylesSupplyR,
													listTagsNonConformities: (clientParameters?.supplyAudits
														? supplyAuditsListTagsNC
														: listTagsNonConformities),
													filterOptions: filterOptions.filter(
														(option) => option.label === "Section"
															|| (clientParameters?.threeLevelNC
																? option.label === "CriticalityLevel" : option.label === "Criticality")
															|| option.label === "Answer"
															|| (!clientParameters?.hideActionPlan && option.label === "NCStatus")
															|| option.label === "Plan Item Delayed"
													)
												}}
												displayActionPlan
												forceObservation
												observations={clientParameters?.scoringBySection
													? [
														{
															locale: "auditorComment",
															accessorResult: (d) => d.auditorResult,
															accessorObs: (d) => d.auditorComment,
															accessorCriticality: (d) => d.criticalityLevel,
															picto: CommentBankIcon
														}, {
															locale: "actionPlanned",
															accessorActionPlanned: (d) => d.actionPlanned,
															picto: ForwardIcon,
															pictoColor: ""
														}
													]
													: [
														{
															locale: "supplierComment",
															accessorResult: (d) => d.supplierResult,
															accessorObs: (d) => d.supplierComment,
															picto: ChatIcon
														},
														{
															locale: "auditorComment",
															accessorResult: (d) => d.auditorResult,
															accessorObs: (d) => d.auditorComment,
															picto: CommentBankIcon
														}, {
															locale: "actionPlanned",
															accessorActionPlanned: (d) => d.actionPlanned,
															picto: ForwardIcon,
															pictoColor: ""
														}
													]}
												totalNonConformities={activeDatasets.totalNonConformities?.data.nbNC ?? 0}
												contentRef={contentRef}
												dataset="listNonConformities"
												locales={appDefinition.locales}
												themePalette="supplyr"
											/>
										</div>
									</div>

								</IF>

								<IF condition={subMenu === "ALTERNATIVES"
									&& (activeDatasets.scores?.data?.[0]?.resilienceScore && activeDatasets.scores?.data?.[0]?.resilienceScore !== 0)}>

									<div className="flex flex-col w-full md:pr-10">

										<TitleCard titleLocal="comparePage.benchmark.title"
											infoLocal="comparePage.benchmark.info" theme={appDefinition.theme} small locales={appDefinition.locales} />

										<div className="flex flex-col space-y-6 w-full pt-6">
											<div className="rounded-xl alphaBlur w-full px-6 py-4">
												<TitleCard titleLocal={"analysis.benchmark.directorate.title"} subTitleLocal=""
													small infoLocal={"analysis.finalProduct.benchmarkSelected.desc"}
													theme={appDefinition.theme} locales={appDefinition.locales} />
												<div className="flex flex-col">
													<ParentSize className="graph-container" debounceTime={10}>
														{(parent) => (
															<ColumnGroupGraph
																appSlice={appSlice}
																dataset1={"finalProductDistribution"}
																dataset2={"finalProductBenchmark"}
																color={"#1876bd"}
																width={parent.width}
																getAbs={(d) => d.value}
																getOrd={(d) => d.finalProduct}
															/>
														)}
													</ParentSize>
													<p className="flex items-center text-medium gap-x-6">
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-supplyr_primary-menu rounded-full" />
															<p>{t("analysis.legend.selectedFactory")}</p>
														</div>
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-gray-500 rounded-full" />
															<p>{t("analysis.legend.allFactories")}</p>
														</div>
													</p>
												</div>
											</div>

											<div className="rounded-xl alphaBlur w-full px-6 py-4">

												<TitleCard titleLocal={"analysis.benchmark.supplierSelected.title"} subTitleLocal=""
													small infoLocal={"analysis.benchmark.supplierSelected.desc"}
													theme={appDefinition.theme} locales={appDefinition.locales} />

												<div className="flex flex-col">
													<ParentSize className="graph-container" debounceTime={10}>
														{(parent) => (
															<ColumnGraph
																appSlice={appSlice}
																dataset={"sitesBenchmark"}
																color={"#1876bd"}
																mapData={(d) => {
																	const element = { ...d };
																	element.inactive = !(element.supplier === activeRefines.supplier &&
																		((activeRefines.siteId === undefined)
																			|| (element.siteId === activeRefines.siteId)));
																	return element;
																}}
																strokeWidth={8}
																width={parent.width}
																margin={{
																	top: 25,
																	left: 10,
																	right: 30,
																	bottom: 0
																}}
																sortScoreDimension
																getScore={(d) => d.resilienceScore}
																getModule={(d) => d.auditedCompany}
																refineKeys={["supplier"]}
																locales={appDefinition.locales}
															/>
														)}
													</ParentSize>
													<p className="flex items-center text-medium gap-x-6">
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-supplyr_primary-menu rounded-full" />
															<p>{t("analysis.legend.selectedFactory")}</p>
														</div>
														<div className="flex items-center gap-x-2">
															<p className="h-2 w-6 bg-gray-500 rounded-full" />
															<p>{t("analysis.legend.allFactories")}</p>
														</div>
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="flex flex-col w-full" ref={contentRef}>

										<TitleCard titleLocal="comparePage.alternatives.title"
											infoLocal="comparePage.alternatives.info" theme={appDefinition.theme} small locales={appDefinition.locales} />

										<div className="flex flex-col w-full pt-6">
											<SupplyRAlternativeList
												appSlice={appSlice}
												datasetSupplyChain="supplyChain"
												locales={appDefinition.locales}
											/>
										</div>
									</div>
								</IF>

							</div>

						</Div100vh>
					</IF>

					{/*
                    * INFO PAGE
                    */}
					<IF condition={menu === "INFO"}>
						<Div100vh className="overflow-y-auto ">
							<SupplyRInfoPageHeader
								appSlice={appSlice}
								page="Info"
								Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
								locales={appDefinition.locales}
							>
								<div className="flex flex-row justify-center item-center">
									<img src={appDefinition.logo} className="w-16 max-h-24 object-scale-down mt-2" />
									<div className="flex flex-col mt-6 ml-3">
										<p className="text-3xl text-white font-medium">{t("Info.header.supplyR")}</p>
										<p className="text-medium w-max text-white tracking-widest uppercase">{t("Info.header.bv")}</p>
									</div>
								</div>
							</SupplyRInfoPageHeader>

							<div data-test="Info" className="-mt-9">
								<Tabs tabSet={infoTabSet} locales={appDefinition.locales} page="Info" />
							</div>

						</Div100vh>
					</IF>

					{/*
                      * TERMS PAGE
                      */}
					<IF condition={menu === "TERMs"}>
						<Div100vh className="overflow-y-auto ">
							<SupplyRTermPageHeader
								appSlice={appSlice}
								page="TermsPage"
								Picto={GavelIcon}
								locales={appDefinition.locales} />

							<div className="flex flex-col w-full justify-center -mt-12 mb-12 px-4 md:px-8 space-y-8 2xl:px-12">
								<Tabs tabSet={termsTabSet} locales={appDefinition.locales} />
							</div>
						</Div100vh>
					</IF>

				</div>
			</div>

		</div>
	);
}

export default Verkor;
