import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

function MigrationNotice() {
	// Internationalization hook
	const { t } = useTranslation("translationsGeneral");

	const [open, setOpen] = useState(false);

	const isPermanentlyClosed = () => localStorage.getItem("migration-notice-general-splashScreen");

	const saveClosedState = () => {
		localStorage.setItem("migration-notice-general-splashScreen", true);
	};

	const openNotification = () => {
		if (isPermanentlyClosed()) return;
		setOpen(true);
	};

	const closeNotification = (e) => {
		if (e) e.preventDefault();
		setOpen(false);
		saveClosedState();
	};

	useEffect(() => {
		openNotification();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={`fixed hidden ${open && "md:flex"} w-full h-full z-3xl`}>
			<div className="absolute w-full h-full bg-opacity-70 bg-gray-600 animate-fade-in-long" />
			<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-auto">
				<div className="relative animate-fade-in-long flex flex-col flex-wrap bg-white w-fit h-[338px] rounded-2xl shadow-xl p-6 gap-6">
					<p className="font-semibold text-xl">{t("migration.title")}</p>
					<p className="w-[20px]">
						{t("migration.content")}
					</p>

					<div className="flex justify-between">
						<div className="space-x-3">
							<input type="checkbox" className="w-fit" onClick={() => closeNotification()} />
							<label>{t("migration.dontremind")}</label>
						</div>
						<Button
							variant="contained"
							style={{
								backgroundColor: "#00049E",
								paddingTop: 0.5,
								paddingBottom: 0.5,
								fontSize: 14,
								textTransform: "none"
							}}
							onClick={() => setOpen(false)}
						>
							{t("migration.closebtn")}
						</Button>

					</div>
				</div>
			</div>
		</div >
	);
}

MigrationNotice.propTypes = {
	appName: PropTypes.string,
	notifKey: PropTypes.string,
	splashScreens: PropTypes.array,
	theme: PropTypes.string,
	locales: PropTypes.string
};

export default MigrationNotice;
