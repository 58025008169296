// import Node Modules
import { useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	BrowserRouter, Routes, Route, useSearchParams
} from "react-router-dom";

import Amplify, { Hub } from "aws-amplify";

import { AuthState } from "@aws-amplify/ui-components";
import Div100vh from "react-div-100vh";

import { fetchUser, updateLoginState, userSelector } from "./data/slices/UserSlice";

// import default css
import "tailwindcss/tailwind.css";
import "./styles/globals.css";

// import default pages
import Authenticator, { oAuthScope, openSaml } from "./auth/Authenticator";
import UserProfile from "./pages/UserProfile";
import Page404 from "./pages/Page404";
import Page403 from "./pages/Page403";

// import Applications
import Launcher from "./apps/Launcher";
import Compass from "./apps/Compass";
import RYB from "./apps/RYB";
import SupplyR from "./apps/SupplyR";
import Clarity from "./apps/Clarity";
import VendorDD from "./apps/VendorDD";
import ChargeScan from "./apps/ChargeScan";
import CEE from "./apps/CEE";
import Admin from "./apps/Admin";
import SCA from "./apps/SCA";
import Verkor from "./apps/Verkor";
import AtAGlance from "./apps/AtAGlance";

// import functions
import Loading from "./utils/Loading";
import awsConfig from "./aws-exports";
import IF from "./utils/IF";
import MigrationNotice from "./migration/MigrationNotice";

// import Amplify config

const updatedAwsConfig = {
	...awsConfig,
	oauth: {
		domain: process.env.REACT_APP_AMPLIFY_OAUTH_DOMAIN ? process.env.REACT_APP_AMPLIFY_OAUTH_DOMAIN : process.env.REACT_APP_OAUTH_DOMAIN,
		scope: oAuthScope.split(" "),
		redirectSignIn: window.location.href.split(/[?#]/)[0],
		redirectSignOut: `${window.location.href.split(/[?#]/)[0].split("/")[0]
			}//${window.location.href.split(/[?#]/)[0].split("/")[2]}/`,
		responseType: "TOKEN"
	}
};

Amplify.configure(updatedAwsConfig);

/**
 * Set up the application routes and permissions access
 * If the user is in the ALPHA group, he will see all the APPS
 *
 * @param {String} url Urr of the page route
 * @param {String} app Name of the app
 * @param {String} group Name of the group defined in the User Mgt needed to see the App (ANY for at least one)
 * @param {Component} component Component to be rendered for the given route/url
 */
const appsConfig = [
	{
		url: "/compass",
		app: "Compass",
		group: "COMPASS",
		private: true,
		component: Compass
	},
	{
		url: "/ryb",
		app: "Restart Your Business",
		group: "ANY",
		private: false,
		component: RYB
	},
	{
		url: "/supplyr",
		app: "Supply-R",
		group: "SUPPLYR",
		private: true,
		component: SupplyR
	},
	{
		url: "/clarity",
		app: "Clarity",
		group: "CLARITY",
		private: true,
		component: Clarity
	},
	{
		url: "/vendordd",
		app: "Vendor DD",
		group: "VENDORDD",
		private: true,
		component: VendorDD
	},
	{
		url: "/chargescan",
		app: "ChargeScan",
		group: "CHARGESCAN",
		private: true,
		component: ChargeScan
	},
	{
		url: "/ControlesEcoEnergie",
		app: "CEE",
		group: "CEE",
		private: true,
		component: CEE
	},
	{
		url: "/sca",
		app: "Supply Chain Analytics",
		group: "SCA",
		private: true,
		component: SCA
	},
	{
		url: "/verkor",
		app: "Verkor",
		group: "VERKOR",
		private: true,
		component: Verkor
	},
	{
		url: "/supplyaudits",
		app: "Verkor",
		group: "VERKOR",
		private: true,
		component: Verkor
	},
	{
		url: "/ataglance",
		app: "AtAGlance",
		group: "ATAGLANCE",
		private: true,
		component: AtAGlance
	},
	// Always put this one at the end as the default url if the others are not valid
	{
		url: "/",
		app: "Launcher",
		group: "ANY",
		private: false,
		component: Launcher
	}
];

function App() {
	// Load the store hook
	const dispatch = useDispatch();

	const requestedApp = appsConfig?.find((e) => (window.location.pathname).startsWith(e.url));

	const isPrivate = requestedApp ? requestedApp.private : true;

	// Get URL search parameters and store them locally to be reused by app after authentication which requires to discard them
	const searchParams = window.location.search;
	if (searchParams !== "") window.localStorage.setItem("searchParams", searchParams);

	// Load data from the store of the App
	const user = useSelector(userSelector);
	const stateSignIn = user.updateLoginState === AuthState.SignIn;

	useEffect(() => {
		Hub.listen("auth", ({ payload: { event, data } }) => {
			switch (event) {
				case "signIn":
				case "cognitoHostedUI":
					dispatch(updateLoginState(AuthState.SignIn));
					break;
				case "signIn_failure":
				case "cognitoHostedUI_failure":
					console.log("Sign in failure", data);
					break;
				default:
					break;
			}
		});
	}, [stateSignIn]);// eslint-disable-line react-hooks/exhaustive-deps

	// Define if the user is logged & retrieve attributes and groups if needed
	useEffect(() => {
		if (!requestedApp
			|| (user.loginState === "noAuth" || user.loginState === AuthState.SignedIn)) {
			return;
		}

		dispatch(fetchUser());
	}, [requestedApp !== undefined, user, user?.loginState]);// eslint-disable-line react-hooks/exhaustive-deps

	// // Open the authentification page if the user is not logged in
	// useEffect(() => {
	// 	if (!(requestedApp !== undefined && (isPrivate || user.loginRequested) && (user.loginState === "noAuth"))) {
	// 		return;
	// 	}

	// 	openSaml();
	// }, [requestedApp !== undefined, user, user?.loginState]);// eslint-disable-line react-hooks/exhaustive-deps

	// TODO: comment this part and resume the part above import when local development is no longer needed
	// Launcher / Authenticator / App / UserProfile / package.json @aws-amplify/ui-react / .env
	if (requestedApp !== undefined && (isPrivate || user.loginRequested) && (user.loginState === "noAuth")) {
		return <Authenticator />;
	}

	// Wait for the user to be loaded
	if (requestedApp !== undefined && isPrivate && ((user?.loginState !== AuthState.SignedIn) || (user?.rights === undefined))) {
		return (
			<div className="flex flex-col flex-grow w-screen h-screen justify-center items-center m-auto">
				<Loading />
			</div>
		);
	}

	// Display 403 page if the current user has no right to see the requested application
	if (requestedApp && isPrivate
		&& (user.rights === undefined ||
			(requestedApp.group !== "ANY" &&
				user.type !== "bv" &&
				user.rights.filter((e) => e.app === "ALPHA").length < 1 &&
				user.rights.filter((e) => e.app === requestedApp.group).length < 1))
	) {
		return <Page403 />;
	}

	// Router of Alpha Applications including Auth
	return (
		<Suspense fallback="loading">
			<MigrationNotice appName="migration" />
			<BrowserRouter>
				<Routes>
					<Route path="/profile" element={<UserProfile />} />
					<Route path="/admin" element={
						<Div100vh>
							<Admin />
						</Div100vh>
					} />
					{appsConfig.map((app) => {
						const AppComponent = app.component;
						return (
							<Route key={`Route ${app.url}`} path={app.url} // exact no longer needed
								element={
									<Div100vh>
										<AppComponent key={`Component ${app.component}`} />
									</Div100vh>
								}
							/>
						);
					})}
					<Route render={() => <Page404 />} />
				</Routes>
			</BrowserRouter>
		</Suspense >
	);
}

export default App;
