import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";

import IF from "../../utils/IF";

const storageKey = "splashScreenViewed";

function Splashscreen({
	appName, notifKey = "splashScreen", splashScreens, theme, locales
}) {
	// Internationalization hook
	const { t } = useTranslation(locales);

	const [open, setOpen] = useState(false);
	const [page, setPage] = useState(0);

	const isPermanentlyClosed = () => localStorage.getItem(`${storageKey}-${appName}-${notifKey}`);

	const saveClosedState = () => {
		localStorage.setItem(`${storageKey}-${appName}-${notifKey}`, true);
	};

	const openNotification = () => {
		if (isPermanentlyClosed()) return;
		setOpen(true);
	};

	const closeNotification = (e) => {
		if (e) e.preventDefault();
		setOpen(false);
		saveClosedState();
	};

	useEffect(() => {
		openNotification();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={`absolute hidden ${open && "md:flex"} w-full h-full z-2xl`}>
			<div className="absolute w-full h-full bg-opacity-70 bg-gray-600 z-2xl animate-fade-in-long" />
			<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-156  m-auto z-2xl">
				<div className="relative animate-fade-in-long flex flex-col bg-white w-full h-full rounded-xl shadow-xl overflow-hidden">
					<img src={splashScreens[page]?.img} className="animate-fade-in" />
					<IF condition={splashScreens[page]?.type === "full"}>
						<div className="flex justify-between gap-x-16 px-10 pt-6">
							<p className="flex-shrink-0 font-semibold">
								{t(`splashscreen.${t(splashScreens[page]?.name)}.title`)}
							</p>
							<p className="text-medium font-light">
								{t(`splashscreen.${t(splashScreens[page]?.name)}.description`)}
							</p>
						</div>
						<div className="flex justify-end items-center gap-x-16 px-10 pt-4 pb-6">
							<p className={`text-medium text-${theme}_primary-menu underline cursor-pointer`}
								onClick={closeNotification}>
								{t("splashscreen.dontRemindMe")}
							</p>
							<button className={`flex items-center justify-center rounded-xl px-10 py-3 
                        bg-${theme}_primary-menu text-white text-medium cursor-pointer`}
								onClick={() => setPage(1)}>
								{t("splashscreen.showMeHow")}
							</button>
						</div>
					</IF>
					<IF condition={splashScreens[page]?.type === "page"}>
						<div className="flex justify-between gap-x-16 px-10 py-6">
							<div className="flex flex-col gap-y-2">
								<p className="flex-shrink-0 font-semibold">
									{t(`splashscreen.${t(splashScreens[page]?.name)}.title`)}
								</p>
								<p className="text-medium font-light">
									{t(`splashscreen.${t(splashScreens[page]?.name)}.description`)}
								</p>
							</div>
							<div className="flex items-center gap-x-2">
								<button className="flex items-center p-2 border rounded-xl text-gray-500 cursor-pointer"
									onClick={() => setPage(page - 1)}>
									<ArrowBackIcon fontSize="small" />
								</button>
								<p className="flex-shrink-0 text-small font-light">
									{page} of {splashScreens.length - 1}
								</p>
								{page !== (splashScreens.length - 1) ?
									<button className={`flex items-center p-2 border bg-${theme}_primary-menu rounded-xl text-white cursor-pointer`}
										onClick={() => setPage(page + 1)}>
										<ArrowForwardIcon fontSize="small" />
									</button>
									:
									<button className={`flex items-center p-2 border bg-${theme}_primary-menu rounded-xl text-white cursor-pointer`}
										onClick={() => setOpen(false)}>
										<CloseIcon fontSize="small" />
									</button>
								}
							</div>
						</div>
					</IF>

					<div className={`absolute top-2 right-2 font-semibold cursor-pointer
                    ${splashScreens[page]?.contrasted ? "text-white" : `text-${theme}_primary-menu`}`}>
						<CloseIcon fontSize="small" onClick={() => setOpen(false)} />
					</div>

				</div>
			</div>
		</div>
	);
}

Splashscreen.propTypes = {
	appName: PropTypes.string,
	notifKey: PropTypes.string,
	splashScreens: PropTypes.array,
	theme: PropTypes.string,
	locales: PropTypes.string
};

export default Splashscreen;
