import React, { useEffect, createRef } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";

import { useTranslation } from "react-i18next";

import { use100vh } from "react-div-100vh";
import { ParentSize } from "@visx/responsive";

import DescriptionIcon from "@mui/icons-material/Description";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import createAppSlice from "../data/slices/createAppSlice";
import SideBar from "../components/menu/SideBar";
import ProgressBar from "../components/graphs/ProgressBar";
import SplitText from "../components/graphs/SplitText";
import ArcChart from "../components/graphs/ArcChart";
import MapLeaflet from "../components/map/MapLeaflet";
import TitleCard from "../components/cards/TitleCard";
import SimpleCard from "../components/cards/SimpleCard";

import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, vendorDDInitDatasets, vendorDDInitRefines, langListValue,
	clientInitParameters, stateConfig, getMarkerColor, getRankColor, vendorDDMapTooltip, tableSectionsStructure
} from "./configs/VendorDDConfig";

import Percent from "../assets/svg/percent.svg";
import { requestSignIn } from "../data/slices/UserSlice";
import { openSaml } from "../auth/Authenticator";
import Page401 from "../pages/Page401";
import SimpleDonutGraph from "../components/graphs/SimpleDonutGraph";
import VendorDDTooltip from "../components/tooltips/VendorDDTooltip";
import VendorDDHeader from "../components/header/VendorDDHeader";
import VendorDDModuleCard from "../components/cards/VendorDDModuleCard";

// Create the App Slice
const appSlice = createAppSlice(appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	vendorDDInitRefines,
	vendorDDInitDatasets);

const mdScreens = 768;

// Static function to get the percentage for a module
const getModulePercentage = (module, data) => {
	if (!module || !data) {
		return undefined;
	}

	const value = (module === "Web evaluation")
		? data?.webPercentage
		: (module === "Product evaluation")
			? data?.productPercentage
			: data?.sitePercentage;

	if (Number.isNaN(+value)) {
		return undefined;
	}

	return value.toFixed(0);
};

function VendorDD() {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Get the screen height
	const screenHeight = use100vh();

	// Load ref of content section
	const topRef = createRef();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const subMenu = useSelector(appSlice.selectSubPage);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	// Load client parameters as a filter for the indicators
	useEffect(() => {
		dispatch(appSlice.fetchDataset(clientInitParameters));
	},
		[activeRefines?.spaceId]); // eslint-disable-line react-hooks/exhaustive-deps

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		if (clientParameters === undefined || loadDataStatus !== "idle") {
			return;
		}

		if (JSON.stringify(clientParameters?.spaceId) !== JSON.stringify(activeRefines?.spaceId)) {
			dispatch(appSlice.actions.refine([{ key: "spaceId", value: clientParameters?.spaceId }]));
		}
	},
		[clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Manage specific actions based on active refines
	useEffect(() => {
		// Set SubPages
		if (menu === "HOME" && activeRefines?.vendorId !== undefined && activeRefines?.module === undefined) {
			dispatch(appSlice.actions.setSubPage("VENDOR"));
		}
		if (menu === "HOME" && activeRefines?.vendorId !== undefined && activeRefines?.module === "Web evaluation") {
			dispatch(appSlice.actions.setSubPage("VENDOR_WEB"));
		}
		if (menu === "HOME" && activeRefines?.vendorId !== undefined && activeRefines?.module === "Product evaluation") {
			dispatch(appSlice.actions.setSubPage("VENDOR_PRODUCT"));
		}
		if (menu === "HOME" && activeRefines?.vendorId !== undefined && activeRefines?.module === "Site evaluation") {
			dispatch(appSlice.actions.setSubPage("VENDOR_SITE"));
		}

		// Reset subPages if needed
		if (subMenu !== undefined
			&& (menu !== "HOME" || activeRefines?.vendorId === undefined)) {
			dispatch(appSlice.actions.setSubPage(undefined));
		}
	}, [activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch and refresh data in the store
	useEffect(() => {
		if (clientParameters === undefined || activeDatasets === undefined
			|| activeRefines.spaceId === undefined
			|| JSON.stringify(clientParameters?.spaceId) !== JSON.stringify(activeRefines?.spaceId)) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [menu, activeRefines, clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("svg-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	const scoreList = activeDatasets?.scoresAnalysis?.data[0];

	const calOverAllScore = (value) => value.reduce((acc, currentValue) => acc
		+ Number((!currentValue.score || Number.isNaN(currentValue.score)) ? 0 : currentValue.score), 0);

	return (
		<React.Fragment>
			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full w-full bg-diag-primary"
				id="full-screen">
				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					colorMenu="#1764FD"
					colorAccent="black"
					langListValue={langListValue}
				/>

				{/*
				* Content of the APP
				*/}
				<div id="content-scroll" className="w-full overflow-y-auto"
					style={{ height: screenHeight }}>
					<div ref={topRef} className="flex-grow md:pl-24"></div>
					<div className="flex flex-col md:pl-24  h-full w-full max-w-full ">

						<VendorDDHeader appSlice={appSlice} locales={appDefinition.locales} />

						<div className="grid grid-cols-1 grid-flow-row gap-y-6 px-4 py-6 w-full
                        md:px-8 md:grid-cols-4 md:gap-x-8 2xl:px-12 full-screen-hide   ">

							<IF condition={menu === "HOME"}>

								<div className="w-full md:col-span-4 " />

								{/*
                                * HOME PAGE
                                */}
								<IF condition={activeRefines.vendorId === undefined}>

									<div className="flex flex-col justify-between gap-y-4 alphaBlur rounded-xl px-4 py-3 h-64 col-span-2 w-full ">

										<div className="flex items-start gap-x-2 text-vendorDD_primary-menu">
											<DonutLargeIcon />
											<div className="mt-1 flex flex-col leading-4">
												<p className="text-black font-semibold">
													{t("homePage.state.title", { count: activeDatasets?.listProducts?.data?.length })}
												</p>
												<p className="text-sm  text-gray-400">{t("homePage.state.subTitle")}</p>
											</div>
										</div>

										<ParentSize debounceTime={10}>
											{(parent) => (
												<div className="flex items-center justify-center">
													<SimpleDonutGraph
														value={activeDatasets?.vendorsDistrib?.data}
														index={false}
														donutThickness={10}
														donutInnerThickness={18}
														width={parent.width / 2}
														height={150}
														values={true}
														donutColor={activeDatasets?.vendorsDistrib?.data
															?.map((value) => getRankColor(value.label))}
														fontSizeTextCenter={40}
														subTextCenter={t("homePage.state.vendor",
															{ count: activeDatasets?.vendorsDistrib?.data?.length ?? 0 })}
														subTextCenterSize={18}
														margin={{
															top: 10,
															right: 0,
															bottom: -10,
															left: 0
														}}
														textInnerColor={"black"}
														calOverAllScore={calOverAllScore}
													/>
													<SimpleDonutGraph
														value={activeDatasets?.sitesDistrib?.data}
														index={false}
														donutThickness={10}
														donutInnerThickness={18}
														width={parent.width / 2}
														height={150}
														values={true}
														donutColor={activeDatasets?.sitesDistrib?.data
															?.map((value) => getRankColor(value.label))}
														fontSizeTextCenter={40}
														subTextCenter={t("homePage.state.site",
															{ count: activeDatasets?.sites?.data?.length ?? 0 })}
														subTextCenterSize={18}
														margin={{
															top: 10,
															right: 0,
															bottom: -10,
															left: 0
														}}
														textInnerColor={"black"}
														calOverAllScore={calOverAllScore}
													/>

												</div>
											)}
										</ParentSize>

										<div className='flex flex-wrap justify-center items-center gap-x-4 text-sm' >
											{stateConfig.map((element, i) => <div key={`legendRating ${i}`}
												className="flex items-center gap-x-2 ">
												<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
												<p className="text-medium">{element.rating}</p>
											</div>
											)}
										</div>

									</div>

									<div className="hidden md:flex flex-col w-full rounded-xl overflow-hidden alphaBlur md:col-span-2 md:row-span-4">
										<ParentSize debounceTime={10}>
											{(parent) => (

												<MapLeaflet
													appSlice={appSlice}
													dataset="sites"
													getMarkerColor={getMarkerColor}
													scoreAccessor={(d) => d.siteOverallScore}
													fieldsTooltip={vendorDDMapTooltip}
													customTooltip={VendorDDTooltip}
													mapType="jawgTerrain"
													minZoom={2}
													locales={appDefinition.locales}
													mapHeight={parent.width}
												/>

											)}
										</ParentSize>
									</div>

									<div className="flex flex-col gap-y-4 w-full md:col-span-2 ">

										<div className="-mb-6">
											<TitleCard titleLocal="homePage.last.title" subTitleLocal="homePage.last.subTitle"
												theme="vendorDD" locales={appDefinition.locales} />
										</div>

										{activeDatasets?.lastVendors?.data?.map((vendor, i) => <div key={`vendorList n${i}`}
											className="flex w-full justify-between items-center px-4 cursor-pointer
                                             rounded-lg text-white bg-vendorDD_primary-menu"
											onClick={() => dispatch(appSlice.actions.refine([{ key: "vendorId", value: vendor.vendorId }]))
											}>
											<div className="flex justify-between items-center w-4/5 md:-my-1 ">
												<div className="flex items-center gap-x-2 font-semibold h-full ">
													<AddBusinessIcon fontSize="large" />
													<div className="flex flex-col justify-center  h-full">
														<p className="text-sm md:text-base uppercase line-clamp-1
                                                        leading-3 md:leading-5">{vendor.vendorId}</p>
														<p className="font-thin text-smaller md:text-small leading-3">
															{vendor.nbSites} {t("homePage.last.location", { count: vendor.nbSites })}
															/ {vendor.nbProducts} {t("homePage.last.product", { count: vendor.nbProducts })}
														</p>
													</div>
												</div>
												<p className="font-black uppercase text-4xl md:text-6xl">
													{vendor.vendorRank}
												</p>
											</div>
											<p className="font-thin uppercase">&nbsp;</p>
										</div>
										)}
									</div>
								</IF>

								{/*
                                * VENDOR HOME PAGE
                                */}
								<IF condition={activeRefines.vendorId !== undefined}>

									<div className="md:hidden flex justify-between items-center  w-full  text-white -mt-6">
										<div onClick={() => activeRefines.module === undefined
											? dispatch(appSlice.actions.refine([{ key: "vendorId", value: undefined }]))
											: dispatch(appSlice.actions.refine([{ key: "module", value: undefined }]))}
											className="flex items-center text-vendorDD_primary-menu alphaBlur
                                            rounded-xl text-2xl font-bold px-2 py-1 cursor-pointer">
											<ArrowBackIcon fontSize="inherit" />
										</div>
										<div className="flex items-end gap-x-2">
											<AddBusinessIcon fontSize="large" />
											<p className="text-2xl font-semibold">{activeRefines.vendorId}</p>
										</div>
									</div>

									<IF condition={activeRefines.module === undefined}>

										<div className="md:col-span-2 bg-white rounded-xl px-4 py-3">
											<p className="font-semibold">{t("homePage.progress.title")}</p>
											<p className="text-sm">{t("homePage.progress.description")}</p>
											<div className="flex justify-center item-center flex-row mt-4">
												<ProgressBar
													className="w-3/5"
													theme="vendorDD"
													percentage={activeDatasets.lastVendors?.data?.[0]?.vendorScore?.toFixed(0)}
													showPercentage={false}
													viewboxWidth="205"
													viewboxHeight="50"
													height="13"
													strokeWidth="13"
													backgroudColor="fill-current text-vendorDD_primary-bg"
													width={200}
													backgroudX={4}
													backgroudY={25}
													lineX1={7}
													lineY1={31.5}
													lineY2={31.5}
													showCircle
												/>
												<div className="relative md:mt-6 ml-10 w-16">
													<p className="absolute ml-3 mt-1 z-10 text-4xl">
														{activeDatasets.lastVendors?.data?.[0]?.vendorScore?.toFixed(0)}
													</p>
													<p className="absolute ml-3 mt-9 z-10 md:text-xl uppercase">
														{activeDatasets.lastVendors?.data?.[0]?.vendorRank}
													</p>
													<img src={Percent} className="absolute z-0 -top-2.5 text-xl md:text-4xl" />
												</div>
											</div>
										</div>
									</IF>

									<IF condition={activeRefines.module !== undefined}>
										<SimpleCard type="full" className="md:col-span-2 rounded-xl">
											<div className="flex py-4 md:px-4 ">
												<div className="hidden md:flex">
													<DescriptionIcon
														style={{ fontSize: 60 }}
														className="flex-shrink-0 fill-current text-vendorDD_primary-menu"
													/>
												</div>
												<div className="flex flex-col ml-4">
													<p className="text-lg uppercase font-bold pb-2 leading-5 md:leading-6 md:text-3xl">
														{activeRefines.module}
													</p>
													<p className="text-small md:text-sm font-light leading-4 md:leading-5">
														{t(`homePage.module.${activeRefines.module} description`)}
													</p>
												</div>
												<div className="relative flex-shrink-0 w-1/2 h-1/2 md:w-52 md:h-52">
													<ArcChart
														percentage={getModulePercentage(activeRefines?.module, activeDatasets.lastVendors?.data?.[0])}
														theme="vendorDD"
														className="flex justify-items-center pr-4"
														showScoreText={false}
														scoretextClass="text-2xl"
														percentOverLap
														sideCicle="fill-current text-vendorDD_primary-menu"
														centralCicle="fill-current text-vendorDD_primary-menu"
													/>
												</div>
											</div>
										</SimpleCard>
									</IF>

									<div className="flex flex-col  bg-white rounded-xl px-4 py-2">
										<p className="w-full font-bold ">{t("homePage.scope.scope")}</p>
										<div className="flex items-center justify-center h-full">
											<SplitText
												theme="vendorDD"
												label1="products"
												value1={new Set(activeDatasets?.listProducts?.data?.map((e) => e.product))?.size}
												value2={new Set(activeDatasets?.sites?.data?.map((e) => e.siteLabel))?.size}
												className="self-center flex justify-items-center w-full h-24 md:h-36"
												textClassName="text-5xl"
											/>
										</div>
									</div>

									<div className="hidden md:flex w-full  md:row-span-2">
										<ParentSize debounceTime={10}>
											{(parent) => (
												<div className="bg-white overflow-hidden rounded-xl ">
													<MapLeaflet
														appSlice={appSlice}
														dataset="sites"
														getMarkerColor={getMarkerColor}
														scoreAccessor={(d) => d.siteOverallScore}
														fieldsTooltip={vendorDDMapTooltip}
														customTooltip={VendorDDTooltip}
														mapType="jawgTerrain"
														minZoom={2}
														locales={appDefinition.locales}
														mapHeight={parent.width}
													/>
												</div>
											)}
										</ParentSize>
									</div>

									<IF condition={activeRefines.module === undefined}>
										<div className="md:col-span-3">
											<p className="md:text-xl font-semibold mb-1">
												{t("homePage.detailedStatus.title")}
											</p>
											<p className="text-small md:text-sm font-light md:w-2/3  leading-4 md:leading-6">
												{t("homePage.detailedStatus.description")}
											</p>
										</div>
									</IF>

									{activeDatasets.listModules?.data?.map((module, i) => (<>
										<IF key={`modul ${i}`} condition={activeRefines.module === undefined}>

											<div key={`module div ${i}`}
												className="flex justify-center bg-white rounded-xl px-4 py-3 text-center
                                                cursor-pointer text-sm md:text-base"
												onClick={() => dispatch(
													appSlice.actions.refine([{
														key: "module",
														value: `${module.module}`
													}])
												)}
											>
												<div className="w-1/2 md:w-3/4">
													<p className="font-bold">{t(`homePage.module.${module.module}`)}</p>
													<p className="font-light text-small md:text-base">
														{t(`homePage.module.${module.module} description`)}
													</p>
													<ArcChart
														percentage={getModulePercentage(module?.module, activeDatasets.lastVendors?.data?.[0])}
														className="pr-6"
														theme="vendorDD"
														showScoreText={false}
														scoretextClass="text-2xl"
														percentOverLap
														sideCicle="fill-current text-vendorDD_primary-menu"
														centralCicle="fill-current text-vendorDD_primary-menu"
													/>
												</div>
											</div>
										</IF>
									</>))}

									<IF condition={activeRefines.module !== undefined}>
										<div className="w-full md:col-span-4 md:-mt-6">
											<TitleCard titleLocal="modulePage.sections.title" subTitleLocal="modulePage.sections.subTitle"
												theme="compass" locales={appDefinition.locales} />
										</div>

										<div className="flex flex-col w-full py-3 gap-y-6 md:col-span-4 ">
											<IF condition={activeRefines.module === "Web evaluation"}>
												<VendorDDModuleCard
													appSlice={appSlice}
													label="Head-office"
													link={activeDatasets?.sectionsWebList?.data?.[0]?.assignmentTechnicalid}
													dataset="sectionsWebList"
													defaultShow
													locales={appDefinition.locales} />
											</IF>
											<IF condition={activeRefines.module === "Site evaluation"}>
												{activeDatasets?.listFacilities?.data
													?.filter((site) => activeRefines.siteId === undefined || activeRefines.siteId === site.siteId)
													?.map((site, i) => <VendorDDModuleCard key={`Site ${i}`}
														appSlice={appSlice}
														label={site.siteLabel}
														link={activeDatasets?.sectionsSiteList?.data?.[0]?.assignmentTechnicalid}
														dataset="sectionsSiteList"
														filterData={(d) => d.siteId === site.siteId}
														locales={appDefinition.locales} />
													)}
											</IF>
											<IF condition={activeRefines.module === "Product evaluation"}>
												{activeDatasets?.listProducts?.data
													?.filter((product) => activeRefines.product === undefined || activeRefines.product === product.product)
													?.map((product, i) => <VendorDDModuleCard key={`Product ${i}`}
														appSlice={appSlice}
														label={product.product}
														link={activeDatasets?.sectionsProductList?.data?.[0]?.assignmentTechnicalid}
														dataset="sectionsProductList"
														filterData={(d) => d.product === product.product}
														locales={appDefinition.locales} />
													)}
											</IF>
										</div>

									</IF>

								</IF>
							</IF>

							<IF condition={menu === "MAP"}>
								<div className="flex flex-col w-full rounded-3xl overflow-hidden md:col-span-4">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<div className="bg-white overflow-hidden rounded-xl ">
												<MapLeaflet
													appSlice={appSlice}
													dataset="sites"
													getMarkerColor={getMarkerColor}
													scoreAccessor={(d) => d.siteOverallScore}
													fieldsTooltip={vendorDDMapTooltip}
													customTooltip={VendorDDTooltip}
													mapType="jawgTerrain"
													minZoom={2}
													locales={appDefinition.locales}
													mapHeight={screenHeight - (window.innerWidth > mdScreens ? 300 : 200)}
												/>
											</div>
										)}
									</ParentSize>
								</div>
							</IF>

							<IF condition={menu === "INFO"}>
								Info
							</IF>

						</div>
					</div>
				</div>
			</div>

		</React.Fragment>
	);
}

export default VendorDD;
