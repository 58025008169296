// Create the App Slice
import React, { useEffect, createRef } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation } from "react-i18next";

import { ParentSize } from "@visx/responsive";
import Div100vh, { use100vh } from "react-div-100vh";

import { sum } from "d3";
import { rollups } from "d3-array";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SpeedIcon from "@mui/icons-material/Speed";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import TimelapseIcon from "@mui/icons-material/Timelapse";

import { Tooltip } from "@mui/material";
import createAppSlice from "../data/slices/createAppSlice";
import { requestSignIn, userSelector } from "../data/slices/UserSlice";

import SideBar from "../components/menu/SideBar";
import ClientHeader from "../components/header/ClientHeader";
import HorizontalFilters from "../components/filters/HorizontalFilters";
import TreeNodesGraph from "../components/tree/TreeNodesGraph";
import { customStylesCompass, customStylesSearchCompass } from "../components/filters/CompassFilterStyle";
import TitleCard from "../components/cards/TitleCard";
import MapLeaflet from "../components/map/MapLeaflet";
import NonConformitiesCard from "../components/cards/NonConformitiesCard";
import ListOfNonConformities from "../components/cards/ListOfNonConformities";
import CompassScopeCard from "../components/cards/CompassScopeCard";
import PageHeader from "../components/header/PageHeader";
import Table from "../components/table/Table";
import PageMenuHeader from "../components/header/PageMenuHeader";
import CompassIndicatorCard from "../components/cards/CompassIndicatorCard";
import ClarityIndicatorCard from "../components/cards/ClarityIndicatorCard";
import AtAGlanceIndicatorCard from "../components/cards/AtAGlanceIndicatorCard";
import AtAGlanceGlobalIndexCard from "../components/cards/AtAGlanceGlobalIndexCard";
import VerticalGauge from "../components/graphs/VerticalGauge";
import ProgressBarFlat from "../components/graphs/ProgressBarFlat";
import AssessementProgressBar from "../components/graphs/AssessementProgressBar";
import ClarityTooltip from "../components/tooltips/ClarityTooltip";
import SupplyRMapMarker from "../components/map/SupplyRMapMarker";
import SupplyRLayer from "../components/map/SupplyRLayer";
import ColorLegend from "../components/legends/ColorLegend";

import Page401 from "../pages/Page401";
import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, atAGlanceInitDatasets, atAGlanceInitRefines, filterOptions, clientInitParameters,
	compassMapTooltip, nonConformities, listNonConformityQuery, CompassCheckpoints, updateListNonConformities,
	listTagsNonConformities, ratingsConfig, tableSectionsStructure, supplyRDomainsOptions, getSupplychainChildren,
	treeTooltipBuilder, colorsAndBreakdowns, getClarityScoreColor, getSupplyRScoreColor
} from "./configs/AtAGlanceConfig";
import {
	domainsOptions, appDefinition as clarityAppDefinition, menuOptions as clarityMenuOptions, clarityInitRefines,
	clarityInitDatasets, clarityMapTooltip, getMarkerColor as getClarityMarkerColor
} from "./configs/ClarityConfig";
import {
	getAuditMode as getSupplyRAuditMode, fieldsTooltip as fieldsTooltipSupplyR
} from "./configs/SupplyRConfig";

import SupplyRIndicator from "../components/graphs/SupplyRIndicator";
import CompassSiteCard from "../components/cards/CompassSiteCard";
import CompassTooltip from "../components/tooltips/CompassTooltip";
import { openSaml } from "../auth/Authenticator";
import AtAGlanceHeader from "../components/header/AtAGlanceHeader";
import ToggleGroupProgramsAtAGlance from "../components/button/ToggleGroupProgramsAtAGlance";
import AtAGlanceSiteDetailedCard from "../components/cards/AtAGlanceSiteDetailedCard";

const appSlice = createAppSlice(appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	atAGlanceInitRefines,
	atAGlanceInitDatasets);

const mdScreens = 768;

function AtAGlance() {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Load user
	const user = useSelector(userSelector);

	// Get the screen height
	const screenHeight = use100vh();

	// Load ref of content section
	const topRef = createRef();
	const SCRef = createRef();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const clientParameters = useSelector(appSlice.selectClientParameters);
	const clarityClientParameters = activeDatasets?.clarityClientParameters?.data[0];
	const isActiveFilter = useSelector(appSlice.isRefined);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);

	const clarityLastCampaign = clarityClientParameters?.campaigns && Math.max(...clarityClientParameters.campaigns.map((e) => e.rank));

	const projectName = activeRefines?.projectName?.[0];

	// eslint-disable-next-line arrow-body-style
	const assessementProgressBarCommonStyles = () => {
		// eslint-disable-next-line max-len
		return "col-start-1 col-span-2 w-full lg:col-start-1 lg1:col-start-3 xl:col-start-4 2xl:col-start-5 3xl:col-start-6 4xl:col-start-7 self-center";
	};

	// eslint-disable-next-line arrow-body-style
	const titleCardCommonStyles = () => {
		return "w-full col-start-1 col-span-2 mt-3";
	};

	// Load client parameters as a filter for the indicators
	useEffect(() => {
		dispatch(appSlice.fetchDataset(clientInitParameters));
	},
		[activeRefines?.projectName]); // eslint-disable-line react-hooks/exhaustive-deps

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		if (clientParameters === undefined || loadDataStatus !== "idle") {
			return;
		}

		if (JSON.stringify(clientParameters?.projectName) !== JSON.stringify(activeRefines?.projectName)) {
			dispatch(appSlice.actions.refine([{ key: "projectName", value: clientParameters?.projectName }]));
		}
	},
		[clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps
	// Fetch and refresh data in the store
	useEffect(() => {
		if (clientParameters === undefined || activeDatasets === undefined
			|| activeRefines?.projectName === undefined) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [activeRefines, clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// In order to refine the supply chain by siteUniqueId which is present in supply chain under property "name"
	// we need both "supplier" and "siteUniqueId" (i.e. "name")
	useEffect(() => {
		if (menu === "SC" && activeRefines?.siteUniqueId !== undefined
			&& loadDataStatus !== "loading"
			&& activeRefines?.supplier === undefined) {
			dispatch(appSlice.actions.refine([{ key: "supplier", value: activeDatasets?.supplyRallSites?.data[0]?.supplier }]));
			dispatch(appSlice.actions.refine([{ key: "name", value: activeRefines?.siteUniqueId }]));
			dispatch(appSlice.actions.refine([{ key: "siteUniqueId", value: undefined }]));
		}

		// Reset name refine (name = siteUniqueId) section selection if another supplier is selected
		if (menu === "SC" && activeRefines?.name !== undefined && loadDataStatus !== "loading"
			&& (activeDatasets.supplyRallSites.data[0].supplier !== activeRefines?.supplier)) {
			dispatch(appSlice.actions.refine([{ key: "name", value: undefined }]));
		}
	}, [loadDataStatus, activeRefines]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch supplychain info (initial only if project page only)
	useEffect(() => {
		if (
			(menu === "SC" || menu === "MAP")
			&& activeRefines?.program
			&& activeDatasets?.[`${activeRefines.program}SupplyChain`]?.data
		) {
			let supplyChain;

			if (activeRefines.program === "clarity") {
				supplyChain = rollups(
					activeDatasets.claritySupplyChain.data,
					(v) => ({
						score: sum(v, (d) => activeRefines.domain === undefined ?
							(d.siteScore ? (d.siteScore * 100).toFixed(0) : -999)
							: (d.domainScores?.[d.domain?.indexOf(activeRefines.domain)]
								? (d.domainScores[d.domain.indexOf(activeRefines.domain)] * 100).toFixed(0)
								: -999)
						)
					}),
					(d) => d.finalProduct,
					(d) => d.tierLevel,
					(d) => d.targetProduct,
					(d) => typeof d.nextSupplier === "string" ? d.nextSupplier : JSON.stringify(d.nextSupplier),
					(d) => [d.siteUniqueId, d.supplier, d.company, d.city, d.country,
					d.product, d.geometry, d.countryRisk, d.criticality, d.siteId, d.type, d.region, d._id,
					d.nextSupplier, d.finalProduct, d.targetProduct, d.tierLevel, d.inactive, d.domains, d.spaceLabel, d.campaignId]
				);
			}

			if (activeRefines.program === "supplyR") {
				supplyChain = rollups(
					activeDatasets.supplyRSupplyChain.data,
					(v) => ({
						resilience: sum(v, (d) => activeRefines.module === undefined ?
							(d?.resilience ?? 0)
							: (d?.resilienceScores?.[d?.module?.indexOf(activeRefines.module)] ?? 0)
						),
						resistance: sum(v, (d) => activeRefines.module === undefined ?
							(d?.resistance ?? 0)
							: (d?.resistanceScores?.[d?.module?.indexOf(activeRefines.module)] ?? 0)
						),
						responsiveness: sum(v, (d) => activeRefines.module === undefined ?
							(d?.responsiveness ?? 0)
							: (d?.responsivenessScores?.[d?.module?.indexOf(activeRefines.module)] ?? 0)
						)
					}),
					(d) => d.finalProduct,
					(d) => Number.parseInt(d.tierLevel, 10),
					(d) => d.targetProduct,
					(d) => d.nextSupplier,
					(d) => [d.siteUniqueId, d.supplier, d.auditedCompany, d.city, d.country,
					d.product, d.geometry, d.countryRisk, d.supplierCriticality, d.siteId, d.type,
					d.region, d.tierLevel, d.tierLevelDisplay, d.client, d.finalProduct,
					d.targetProduct, d.nextSupplier, d.logisticAgent, d.tierLabel, d._id, d.siteLabelId,
					d.supplierCode, d.factoryCode, d.module, d.criticality, d.factoryBranch, d.factoryIndex]

				);
			}

			const rawSupplychain = {
				name: "client",
				children: supplyChain
					?.sort((a, b) => a[0] > b[0] ? 1 : -1)
					?.map((finalProduct, i) => ({
						name: finalProduct[0],
						children: getSupplychainChildren(activeRefines, finalProduct, clarityLastCampaign)
					}))
			};

			// inject calculated dataset in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: `${activeRefines.program}SupplyChainTree`,
					datasetData: rawSupplychain
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientParameters, activeRefines?.program, activeDatasets?.[`${activeRefines.program}SupplyChain`]?.data, activeDatasets?.nonConformities, menu]);

	// Fetch calculated Data to be put in the store
	useEffect(() => {
		// Fetch total number of non conformities in the store
		if (activeDatasets?.nonConformities?.data?.length > 0) {
			const totalNonConformities = activeDatasets.nonConformities
				.data
				// ?.concat(activeDatasets?.clarityNonConformities?.data.filter((e) => e.campaignId === clarityLastCampaign))
				// // ?.concat(activeDatasets?.supplyRNonConformities?.data)
				?.reduce((acc, element) => { acc.nbNC += element.nbNC; acc.nbCNC += element.nbCNC; return acc; },
					{ nbNC: 0, nbCNC: 0 });

			// Dispatch the number of non conformities in the store
			dispatch(
				appSlice.actions.fetchCalculatedDataset({
					datasetName: "totalNonConformities",
					datasetData: totalNonConformities
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDatasets?.nonConformities
		// activeDatasets?.clarityNonConformities, activeDatasets?.supplyRNonConformities
	]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (activeRefines?.siteUniqueId !== undefined) {
			dispatch(appSlice.actions.setSubPage("SITE"));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRefines]);

	// let clarityMaturityIndex;
	// if (clarityLastCampaign && activeDatasets?.clarityMaturityIndex.data) {
	// 	clarityMaturityIndex = activeDatasets?.clarityMaturityIndex?.data?.((e) => e.campaignId === clarityLastCampaign);
	// }
	// const clarityMaturityIndex = activeDatasets?.clarityMaturityIndex?.data?.((e) => e.campaignId === clarityLastCampaign);
	// console.log({ clarityMaturityIndex });
	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			setTimeout(() => {
				dispatch(appSlice.actions.reset());
				dispatch(requestSignIn());
				openSaml();
			}, 2000);
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("content-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, activeRefines?.siteId, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	if (loadDataStatus === "sessionExpired") {
		return <Page401 />;
	}

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	// Accessor for the siteScore - use the domain score if a domain is selected
	const scoreAccessor = (d) => d.additionalInfo.finalRating;

	// Used for Clarity's map
	// Accessor for the siteScore - use the domain score if a domain is selected
	const scoreAccessorClarity = (d) => activeRefines.domain === undefined
		? (d.siteScore * 100)
		: ((d.domainScores?.[d.domain?.indexOf(activeRefines.domain)] ?? 0) * 100);

	const scoreCountryAccessorClarity = (d) => activeRefines.domain === undefined
		? (d.countryScore * 100)
		: ((d.domainScores?.[d.domain?.indexOf(activeRefines.domain)] ?? 0) * 100);

	function getModuleScore(domain, data, prevData) {
		if (!domain) {
			return null;
		}

		const moduleScore = {
			score: 0, nbSites: 0, nbNC: 0, nbCNC: 0, trend: undefined
		};
		let nbSections = 0;
		let uniqueSitesArray = [];

		data.filter((module) => module?.domain === domain).forEach((section) => {
			uniqueSitesArray = Array.from(new Set([...uniqueSitesArray, ...section.uniqueSites]));
			nbSections += 1;
			moduleScore.score += section.moduleScore;
			moduleScore.nbNC += section.moduleNbNC;
			moduleScore.nbCNC += section.moduleNbCNC;
		});

		moduleScore.nbSites = uniqueSitesArray.length;

		moduleScore.score = ((moduleScore.score
			/ (nbSections > 1 ? nbSections : 1)) * 100).toFixed(0);

		if (prevData?.length > 0) {
			moduleScore.trend = Number(moduleScore.score) > (prevData[0].moduleScore * 100)
				? "Up"
				: Number(moduleScore.score) < (prevData[0].moduleScore * 100)
					? "Down"
					: "";
		}
		return moduleScore;
	}
	return (
		<div className="relative h-full w-full">

			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full bg-clarity_primary-bg"
				id="full-screen">

				{/*
				* Menu Bar of the AtAGlance APP
				*/}
				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					// noTitle
					altTitle="At A Glance"
					colorMenu="#4DC490"
					colorBgMenu="#FFF"
					textColor="text-[#2A2B2A]"
					colorAccent="#4DC490"
					indicator={{ menu: "NCs", value: activeDatasets?.totalNonConformities?.data?.nbCNC ?? 0 }}
					locales={appDefinition.locales}
				/>

				{/*
				* Content of the APP
				*/}
				<div id="content-scroll" className="w-full overflow-y-auto"
					style={{ height: screenHeight }}>
					<div ref={topRef} className="flex-grow md:pl-24">
						<IF condition={(menu !== "HOME" || activeRefines.module === undefined)
							&& activeRefines.siteId === undefined}>
							<AtAGlanceHeader appSlice={appSlice} locales={appDefinition.locales} appDefinition={appDefinition} />
						</IF>

						<IF condition={(menu === "HOME" && activeRefines.module !== undefined)
							|| activeRefines.siteId !== undefined}>
							<div className="md:-ml-4" style={{ width: (window.innerWidth > mdScreens ? "calc(100% + 16px)" : "100%") }}>
								<PageMenuHeader
									appSlice={appSlice}
									Picto={activeRefines.module !== undefined ? VerifiedUserIcon : BusinessIcon}
									filterOption={filterOptions.find((option) => option.label === "Modules")}
									customStylesSearch={customStylesSearchCompass}
									themePalette="compass"
									locales={appDefinition.locales}
								/>
							</div>
						</IF>

						<div className="grid grid-flow-row gap-x-4 gap-y-4 px-4 py-6 md:px-8 2xl:px-12
						grid-cols-2
						md1:grid-cols-3
						lg1:grid-cols-4
						xl:grid-cols-5
						2xl:grid-cols-6
						3xl:grid-cols-7
						4xl:grid-cols-8
						">
							<IF condition={menu === "HOME" && activeRefines.module === undefined && activeRefines.siteId === undefined}>
								<IF condition={activeRefines.siteUniqueId === undefined && activeRefines.supplier === undefined}>
									<div className="mb-6 md:mb-10 mt-2 md:col-span-3">
										<p className="text-4xl font-bold mb-1">{t("homePage.header.welcome")}</p>
										<div className="flex justify-between">
											<p className="pt-1 md:text-lg w-full md:w-2/3 font-light leading-6">
												{t("homePage.header.introduction")}
											</p>
										</div>
									</div>
								</IF>
								{/* SITE SELECTED PAGE */}
								<IF condition={activeRefines.siteUniqueId !== undefined || activeRefines.supplier !== undefined}>
									<div className="col-span-2 md:col-span-2 md1:col-span-3 lg:col-span-2 lg1:col-span-2 xl:col-span-2 2xl:col-span-3
									drop-shadow-lg">
										<AtAGlanceSiteDetailedCard
											appSlice={appSlice} dataset="listEntities"
											extraDataSet="numberOfAssessmentsPerSupplier"
											programs={["Clarity", "Supply-R"]}
										/>
									</div>
									<div className="rounded-lg overflow-hidden drop-shadow-lg col-span-full lg:col-start-3 2xl:col-start-4">
										<MapLeaflet
											appSlice={appSlice}
											dataset="clarityallSites"
											datasetCountries="clarityCountries"
											scoreAccessor={scoreAccessorClarity}
											countryScoreAccessor={scoreCountryAccessorClarity}
											fieldsTooltip={clarityMapTooltip}
											mapType="jawgTerrain"
											minZoom={2}
											maxZoom={18}
											scrollWheelZoom={true}
											getMarkerColor={getClarityMarkerColor}
											customTooltip={ClarityTooltip}
											mapHeight={220}
											wheelPxPerZoomLevel={110}
											locales={appDefinition.locales}
										/>
										{activeRefines.siteUniqueId !== undefined && <div
											className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
											<LocationOnIcon fontSize="small" />
											<p className="line-clamp-1">
												{activeDatasets.clarityallSites?.data?.[0]?.country}&nbsp;-&nbsp;
												{activeDatasets.clarityallSites?.data?.[0]?.city}&nbsp;-&nbsp;
												{activeDatasets.clarityallSites?.data?.[0]?.address}
											</p>
										</div>}
									</div>
								</IF>
								{/* COMPASS INDICATORS HERE   */}
								{/* <div className="w-full md:col-span-4 ">
									<TitleCard titleLocal="homePage.modules.title" subTitleLocal="homePage.details.subTitle"
										infoLocal="homePage.modules.info" theme="compass" locales={appDefinition.locales} />
								</div>
								{activeDatasets.listModules.data.map((element, i) => <CompassIndicatorCard
									key={`CompassIndicatorCard ${ i}`}
									appSlice={appSlice}
									module={element.module}
									ratingsConfig={clientParameters?.rating}
									locales={appDefinition.locales}/>
								)}

								<div className='flex flex-wrap mt-4 justify-center items-center gap-x-4 md:col-span-4 text-sm' >
									<div className="font-semibold pr-4 border-r">Legend</div>
									{clientParameters?.rating.map((element, i) => <div key={`legendRating ${ i}`}
										className="flex items-center gap-x-2 ">
										<div className="h-4 w-4 rounded-full" style={{ backgroundColor: element.color }} />
										<p className="text-medium">{element.k}</p>
									</div>
									)}
								</div> */}
								{activeDatasets.clarityModulesScoresBis.data
									?.filter((e) => e.campaignId === (clarityLastCampaign || 1)).length > 1 &&
									(<>
										<div className={titleCardCommonStyles()}>
											<TitleCard titleLocal="homePage.modules.title" subTitleLocal="homePage.details.subTitle"
												infoLocal="homePage.tooltip.ClarityInfo" theme="compass" locales={appDefinition.locales}
												versionWithIcon={true} program={"Clarity"} description={"My Sustainability Maturity"}
												projectName={projectName} spaceLabel={clarityClientParameters?.spaceLabel}
												site={activeDatasets?.siteIDs?.data?.find((site) => (
													activeRefines?.siteUniqueId ? site.siteUniqueId === activeRefines?.siteUniqueId
														: site.supplier === activeRefines?.supplier
												))} activeRefines={activeRefines} />
										</div>
										{/* Show assessment progress bar only for aggregated view */}
										<IF condition={activeRefines.siteUniqueId === undefined}>
											<div className={assessementProgressBarCommonStyles()}>
												<AssessementProgressBar appSlice={appSlice} locales={appDefinition.locales} program={"Clarity"} />
											</div>
										</IF>
										{activeDatasets?.clarityMaturityIndex.data?.length > 0 &&
											<div className="col-span-2 col-start-1 relative h-[226px]">
												<AtAGlanceGlobalIndexCard
													appSlice={appSlice}
													moduleScore={activeDatasets?.clarityMaturityIndex.data
														?.filter((e) => e.campaignId === (clarityLastCampaign || 1))[0]}
													externalLink
													displayNbSites={false}
													isPercent
													title={"Overall\nMaturity"}
													locales={clarityAppDefinition.locales}
													program={"clarity"}
													site={activeDatasets?.siteIDs?.data?.find((site) => (
														activeRefines?.siteUniqueId ? site.siteUniqueId === activeRefines?.siteUniqueId
															: site.supplier === activeRefines?.supplier
													))}
												/>
											</div>
										}
										{activeDatasets.clarityModulesScoresBis.data
											?.filter((e) => clarityClientParameters?.activeIndicators.includes(e.domain))
											?.filter((e) => e.campaignId === (clarityLastCampaign || 1))
											.map((element, i) => {
												const moduleScore = getModuleScore(element.domain, activeDatasets?.clarityModulesScoresBis?.data
													?.filter((e) => e.campaignId === (clarityLastCampaign || 1) && e.domain === element.domain));
												return <div key={`indicator ${i}`} className="h-[226px]">
													<AtAGlanceIndicatorCard
														key={`indicator ${i}`}
														appSlice={appSlice}
														module={domainsOptions[element.domain]}
														moduleKey={element.domain}
														moduleScore={moduleScore}
														externalLink
														displayNbSites={false}
														isPercent
														locales={clarityAppDefinition.locales}
														program={"clarity"}
														site={activeDatasets?.siteIDs?.data?.find((site) => (
															activeRefines?.siteUniqueId ? site.siteUniqueId === activeRefines?.siteUniqueId
																: site.supplier === activeRefines?.supplier
														))}
													/>
												</div>;
											})}
									</>)}
								{activeDatasets?.supplyRScoresModule?.data.length > 0 && <>
									<div className={titleCardCommonStyles()}>
										<TitleCard titleLocal="homePage.modules.title" subTitleLocal="homePage.details.subTitle"
											infoLocal="homePage.tooltip.SupplyRInfo" theme="compass" locales={appDefinition.locales}
											versionWithIcon={true} program={"Supply-R"} description={"My Supply Chain Resilience"}
											projectName={projectName}
											site={activeDatasets?.siteIDs?.data?.find((site) => (
												activeRefines?.siteUniqueId ? site.siteUniqueId === activeRefines?.siteUniqueId
													: site.supplier === activeRefines?.supplier
											))} activeRefines={activeRefines} />
									</div>
									{/* Show assessment progress bar only for aggregated view */}
									<IF condition={activeRefines.siteUniqueId === undefined}>
										<div className={assessementProgressBarCommonStyles()}>
											<AssessementProgressBar appSlice={appSlice} locales={appDefinition.locales} program={"SupplyR"} />
										</div>
									</IF>
									{activeDatasets?.supplyRScoresModule?.data.length > 0 &&
										<div className="col-span-2 col-start-1 relative">
											<AtAGlanceGlobalIndexCard
												appSlice={appSlice}
												moduleScore={activeDatasets?.supplyRScoresModule.data[0]}
												externalLink
												displayNbSites={false}
												isPercent
												title={"Overall\nResilience"}
												locales={clarityAppDefinition.locales}
												program={"supplyr"}
												site={activeDatasets?.siteIDs?.data?.find((site) => (
													activeRefines?.siteUniqueId ? site.siteUniqueId === activeRefines?.siteUniqueId
														: site.supplier === activeRefines?.supplier
												))}
											/>
										</div>
									}
									{activeDatasets?.modulesDistribution?.data?.map((dimension, i) => {
										const moduleScore = dimension?.value?.toFixed(0);
										const resistanceScore = dimension?.resistance?.toFixed(0);
										const responsivenessScore = dimension?.responsiveness?.toFixed(0);
										const moduleOption = supplyRDomainsOptions[dimension.module];
										const auditMode = getSupplyRAuditMode(dimension.auditType, dimension.auditMode);
										const Picto = auditMode.picto;
										return (
											<div key={`indicator ${i}`}
												className="items-center px-2 md:px-4 pt-1 gap-y-2 bg-white rounded-xl
													border border-transparent hover:border-gray-300 h-[226px]">
												<AtAGlanceIndicatorCard
													key={`indicator ${i}`}
													appSlice={appSlice}
													module={moduleOption}
													moduleKey={dimension.module}
													moduleScore={dimension}
													externalLink
													displayNbSites={false}
													isPercent
													border={false}
													getAltBarColor={getSupplyRScoreColor}
													altBarScore1={resistanceScore}
													altBarScore2={responsivenessScore}
													locales={appDefinition.locales}
													program={"supplyr"}
													site={activeDatasets?.siteIDs?.data?.find((site) => (
														activeRefines?.siteUniqueId ? site.siteUniqueId === activeRefines?.siteUniqueId
															: site.supplier === activeRefines?.supplier
													))}
												/>

												{clientParameters?.allowReportLink && <div className="flex justify-center items-center w-full">
													<a target="_blank" rel="noopener noreferrer"
														href={`https://odsdatahub.bureauveritas.com/reports/${dimension?.reports?.[0]}`}
														className="flex justify-center items-center space-x-1
                                                                    border border-gray-200 rounded-lg px-3 py-2 text-medium font-light w-28
                                                                    hover:border-gray-400" >
														<Tooltip title={t(auditMode.locale)} placement="top">
															<Picto fontSize="small" />
														</Tooltip>
														<p className="flex flex-shrink-0">{t("NCsPage.listNCs.nonConformity.viewReports")}</p>
													</a>
												</div>}
											</div>
										);
									})}
								</>}

							</IF>

							{/*
                            * SUPPLYCHAIN PAGE
                            */}
							<IF condition={menu === "SC"}>
								<Div100vh id="supplyChain" ref={SCRef}
									className="relative flex flex-col md:col-span-full max-h-full pt-1 px-4">
									<div className="grid items-baseline font-semibold  full-screen-hide pt-3 md:pt-5">
										<div className="flex flex-col">
											<TitleCard titleLocal="homePage.supplyChain.title" subTitleLocal="homePage.supplyChain.subTitle"
												infoLocale="" locales={appDefinition.locales} />
										</div>
										<div className="col-span-2 col-start-1 pb-4 lg:col-start-3 lg:justify-self-end">
											<ToggleGroupProgramsAtAGlance {...{ appSlice }} />
										</div>
									</div>

									<div className="absolute z-500 bottom-20 right-20 flex flex-row">
										<ColorLegend
											locales={appDefinition.locales}
											colorLegend={colorsAndBreakdowns[activeRefines?.program?.toLowerCase()]}
											scale="scale-125"
											legendFontSize="text-8"
											indicatorFontSize={12}
											isRounded={true}
										/>
									</div>

									<div className="relative flex-grow  w-full rounded-xl alphaBlur"
										style={{ height: (screenHeight - 200) }}>
										<div id="svg-scroll" className="w-full h-full overflow-x-auto overflow-y-auto no-scrollbar">
											{activeDatasets?.[`${activeRefines.program}SupplyChain`]?.data?.length > 0 &&
												<ParentSize debounceTime={10}>
													{(parent) => (
														<TreeNodesGraph
															appDefinition={appDefinition}
															appSlice={appSlice}
															dataset={`${activeRefines.program}SupplyChain`}
															datasetTree={`${activeRefines.program}SupplyChainTree`}
															datasetFinalProduct={`${activeRefines.program}ChainActiveFinalProducts`}
															margin={{
																top: 20, left: 0, right: 0, bottom: 180
															}}
															subTreeMargin={{
																top: 56, left: -130, right: 0, bottom: 0
															}}
															refineHighlightKeys={[
																"supplier",
																"name",
																"product",
																"region",
																"country",
																"campaignId",
																"tierLevel"
															]}
															refineSiteKeys={["supplier", "name"]}
															fieldsToExport={["supplier", "auditedCompany",
																"address", "city", "country", "region",
																"finalProduct", "product", "tierLevel", "tierLabel",
																"siteScore", "sumNbOpenedNc"]}
															onlyAudited
															getMarkerColor={activeRefines.program === "clarity" ?
																getClarityScoreColor : getSupplyRScoreColor}
															height={parent.height}
															width={parent.width}
															TreeTooltip={treeTooltipBuilder(activeRefines).tooltip}
															displayTooltip={treeTooltipBuilder(activeRefines).displayTooltip}
															enableNodeDispatch={false}
														/>
													)}
												</ParentSize>
											}
										</div>
									</div>
									<p>&nbsp;</p>
								</Div100vh>
							</IF>

							{/*
                            * MAP PAGE
                            */}
							<IF condition={menu === "MAP" && activeRefines.siteId === undefined}>
								<div className="grid justify-between items-baseline col-span-full font-semibold  full-screen-hide pt-3 md:pt-5">
									<div className="flex flex-col">
										<TitleCard titleLocal="mapPage.header.title" subTitleLocal="mapPage.header.subTitle"
											infoLocale="" locales={appDefinition.locales} />
									</div>
									<div className="col-span-2 col-start-1 lg:col-start-3 lg:justify-self-end">
										<ToggleGroupProgramsAtAGlance {...{ appSlice }} />
									</div>
								</div>
								<div className="flex flex-col w-full rounded-2xl overflow-hidden col-span-full mt-4 md:mt-0">
									{activeRefines?.program === "clarity" &&
										<>
											<MapLeaflet
												appSlice={appSlice}
												dataset="clarityallSites"
												datasetCountries="clarityCountries"
												scoreAccessor={scoreAccessorClarity}
												countryScoreAccessor={scoreCountryAccessorClarity}
												fieldsTooltip={clarityMapTooltip}
												mapType="jawgTerrain"
												minZoom={2}
												scrollWheelZoom={true}
												getMarkerColor={getClarityScoreColor}
												customTooltip={ClarityTooltip}
												mapHeight={640}
												wheelPxPerZoomLevel={110}
												locales={appDefinition.locales}
											/>
											<div className="relative z-500 bottom-[50px] self-end right-20 flex flex-row -mb-[24px]">
												<ColorLegend
													locales={appDefinition.locales}
													colorLegend={colorsAndBreakdowns[activeRefines?.program?.toLowerCase()]}
													scale="scale-125"
													legendFontSize="text-8"
													indicatorFontSize={12}
													isRounded={true}
												/>
											</div>
										</>
									}
									{activeRefines?.program === "supplyR" &&
										<>
											<MapLeaflet
												appSlice={appSlice}
												// dataset="sites"
												dataset="supplyRallSites"
												markersTree={activeDatasets?.supplyRSupplyChainTree?.data}
												fieldsTooltip={fieldsTooltipSupplyR}
												getMarkerColor={getSupplyRScoreColor}
												mapType="jawgTerrain"
												minZoom={2}
												zoom={2}
												maxClusterRadius={35}
												scrollWheelZoom={true}
												defaultColor="#d3d3d3"
												// mapWidth={window.innerWidth > mdScreens ? contentWidth : window.innerWidth}
												// mapHeight={parent.height}
												mapHeight={640}
												CustomMarker={SupplyRMapMarker}
												CustomLayer={SupplyRLayer}
												zoomSnap={0}
												wheelPxPerZoomLevel={110}
											/>
											<div className="relative z-500 bottom-[50px] self-end right-20 flex flex-row -mb-[24px]">
												<ColorLegend
													locales={appDefinition.locales}
													colorLegend={colorsAndBreakdowns[activeRefines?.program?.toLowerCase()]}
													scale="scale-125"
													legendFontSize="text-8"
													indicatorFontSize={12}
													isRounded={true}
												/>
											</div>
										</>
									}
								</div>
							</IF>

							{/*
                            * NON CONFORMITIES PAGE
                            */}
							{/* <IF condition={menu === "NCs" && activeRefines.siteId === undefined}>

								<div className="hidden md:flex flex-col  w-full md:col-span-4">
									<PageHeader
										page="NCsPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>
								<div className="flex flex-col w-full alphaBlur rounded-lg mt-4 md:mt-0 px-4 md:px-6 py-4 md:col-span-4">
									<ListOfNonConformities
										appSlice={appSlice}
										appNCConfig={{
											nonConformities,
											listNonConformityQuery,
											nonConformityDataset: CompassCheckpoints,
											updateListNonConformities,
											listTagsNonConformities,
											customStylesSubFilter: undefined,
											filterOptions: filterOptions.filter((option) => option.label === "Section" || option.label === "Criticality")
										}}
										dataset="listNonConformities"
										totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
										contentRef={topRef}
										themePalette="compass"
										demoReportLink="88cbfabe-45b5-49bd-92b9-ea1016bf52ce"
										locales={appDefinition.locales} />
								</div>

							</IF> */}

							{/*
                            * SITES TABLE PAGE
                            */}
							{/* <IF condition={menu === "SITEs" && activeRefines.siteId === undefined}>
								<div className="hidden md:flex flex-col  w-full md:col-span-4">
									<PageHeader
										page="SitesPage"
										Picto={menuOptions.filter((option) => option.menu === menu)?.[0]?.picto}
										locales={appDefinition.locales}
									/>
								</div>
								<div className="flex flex-col w-full alphaBlur rounded-lg mt-4 md:mt-0  py-4  md:col-span-4">
									<Table
										appSlice={appSlice}
										dataset="sites"
										structureTable={tableStructure}
										sortByRefineFields={[{ id: "cnc", desc: true },
											{ id: "nc", desc: true }]}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

							</IF> */}

							{/*
                            * SELECTED MODULE or SITE PAGE
                            */}
							<IF condition={(menu === "HOME" && activeRefines.module !== undefined)
								|| activeRefines.siteId !== undefined}>

								<div className="-mt-28 md:-mt-36 md:col-span-2 flex gap-x-2 h-64 w-full">
									<div onClick={() => dispatch(appSlice.actions
										.refine([{ key: (activeRefines.siteId === undefined ? "module" : "siteId"), value: undefined }]))}
										className="flex items-center text-clarity_primary-accent bg-white rounded-xl
                                                   text-2xl font-bold px-2 cursor-pointer h-10 w-10 z-40">
										<ArrowBackIcon fontSize="inherit" />
									</div>
									{activeRefines.siteId === undefined && <CompassIndicatorCard
										appSlice={appSlice}
										module={activeRefines.module}
										ratingsConfig={clientParameters?.rating}
										large
										locales={appDefinition.locales} />
									}
									{activeRefines.siteId !== undefined && <div className="alphaBlur rounded-xl px-2 py-3 w-full h-full">
										<CompassSiteCard appSlice={appSlice} siteData={activeDatasets.sites?.data?.[0]}
											demoReportLink="88cbfabe-45b5-49bd-92b9-ea1016bf52ce" locales={appDefinition.locales} />
									</div>
									}
								</div>

								<div className="relative md:-mt-36 h-52 md:h-64 flex flex-col w-full  overflow-hidden rounded-lg md:col-span-2">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												getMarkerColor={getClarityMarkerColor}
												scoreAccessor={scoreAccessor}
												fieldsTooltip={compassMapTooltip}
												customTooltip={CompassTooltip}
												mapType="jawgTerrain"
												maxClusterRadius={30}
												minZoom={2}
												maxZoom={10}
												mapHeight={parent.height}
												locales={appDefinition.locales}
											/>
										)}
									</ParentSize>
									{activeRefines.siteId !== undefined && <div
										className="absolute bottom-6 left-1/2 transform -translate-x-1/2 w-4/5 gap-x-2 font-light text-medium
                                               flex justify-center items-center rounded-xl alphaBlur border px-3 py-1 z-500">
										<LocationOnIcon fontSize="small" />
										<p className="line-clamp-1">
											{activeDatasets.sites?.data?.[0]?.site.country}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.site.city}&nbsp;-&nbsp;
											{activeDatasets.sites?.data?.[0]?.site.address}
										</p>
									</div>}
								</div>

								<div className="w-full md:col-span-4 mt-6">
									<TitleCard titleLocal="modulePage.sections.title" subTitleLocal="modulePage.sections.subTitle"
										infoLocal="modulePage.sections.info" theme="compass" locales={appDefinition.locales} />
								</div>

								<div className="flex flex-col w-full alphaBlur rounded-lg py-4 md:col-span-4 ">
									<Table
										pageTable="modulePage"
										appSlice={appSlice}
										dataset="sectionNCsList"
										structureTable={tableSectionsStructure}
										sortByRefineFields={[{ id: "cnc", desc: true },
										{ id: "nc", desc: true }]}
										themePalette="compass"
										locales={appDefinition.locales} />
								</div>

								<div className="w-full md:col-span-4 mt-6">
									<TitleCard titleLocal="modulePage.nonconformities.title" subTitleLocal="modulePage.nonconformities.subTitle"
										infoLocal="modulePage.nonconformities.info" theme="compass" locales={appDefinition.locales} />
								</div>

								<div className="flex flex-col w-full alphaBlur rounded-lg px-6 py-4 md:col-span-4 ">
									<ListOfNonConformities
										appSlice={appSlice}
										appNCConfig={{
											nonConformities,
											listNonConformityQuery,
											nonConformityDataset: CompassCheckpoints,
											updateListNonConformities,
											listTagsNonConformities,
											customStylesSubFilter: undefined,
											filterOptions: filterOptions.filter((option) => option.label === "Section" || option.label === "Criticality")
										}}
										dataset="listNonConformities"
										totalNonConformities={activeDatasets.totalNonConformities?.data?.nbNC ?? 0}
										contentRef={topRef}
										themePalette="compass"
										demoReportLink="88cbfabe-45b5-49bd-92b9-ea1016bf52ce"
										locales={appDefinition.locales} />
								</div>

							</IF>

						</div>

					</div>
				</div>

				{/*
				* FILTER SECTION OF THE PAGE
				*/}
				{/*
				<HorizontalFilters appSlice={appSlice} theme="compass" filterOptions={filterOptions} initRefines={atAGlanceInitRefines}
					siteMenuLabel="Site" classTitle="compass_primary-accent" filterDesktop
					customStyle={customStylesCompass} customStyleSearch={customStylesSearchCompass}
					isActiveFilter={isActiveFilter} locales={appDefinition.locales}>
					<CompassScopeCard appSlice={appSlice} dataset="scope"
						expectedTotal={clientParameters?.expectedTotal}
						locales={appDefinition.locales} />
				</HorizontalFilters> */}

			</div>

		</div>
	);
}

export default AtAGlance;
