import { useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import Moment from "react-moment";

import BusinessIcon from "@mui/icons-material/Business";
import SettingsInputSvideoIcon from "@mui/icons-material/SettingsInputSvideo";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RoomIcon from "@mui/icons-material/Room";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { ParentSize } from "@visx/responsive";
import { use100vh } from "react-div-100vh";

import createAppSlice from "../data/slices/createAppSlice";

import SideBar from "../components/menu/SideBar";
import ClearButton from "../components/filters/ClearButton";
import MapLeaflet from "../components/map/MapLeaflet";
import ChargeScanMapMarker from "../components/map/ChargeScanMapMarker";
import ChargeScanMobileFilterHeader from "../components/header/ChargeScanMobileFilterHeader";

import IF from "../utils/IF";
import Loading from "../utils/Loading";

import {
	appDefinition, menuOptions, chargeScanInitDatasets, chargeScanInitRefines, filterOptions, clientInitParameters,
	chargeScanMapTooltip, getMarkerColor, ChargingStationPicto, moduleOptions, additionalFilters, sectionStructure
} from "./configs/ChargeScanConfig";
import FilterMenu from "../components/filters/FilterMenu";
import SwitchSelector from "../components/filters/SwitchSelector";
import ChargeScanArcChart from "../components/graphs/ChargeScanArcChart";
import ChargeScanNcStatus from "../components/cards/ChargeScanNcStatus";
import ProgressBarFlat from "../components/graphs/ProgressBarFlat";
import ColumnGraph from "../components/graphs/ColumnGraph";

import SectionsTable from "../components/table/SectionsTable";
import ChargeScanStationTable from "../components/table/ChargeScanStationTable";

import HorizontalFilters from "../components/filters/HorizontalFilters";
import { customStylesChargeScan, customStylesSearchChargeScan } from "../components/filters/ChargeScanFilterStyle";
import { requestSignIn } from "../data/slices/UserSlice";
import { openSaml } from "../auth/Authenticator";
import TitleCard from "../components/cards/TitleCard";

// Create the App Slice
const appSlice = createAppSlice(appDefinition.slice,
	menuOptions.filter((option) => option.initial)[0].menu,
	chargeScanInitRefines,
	chargeScanInitDatasets);

function ChargeScan() {
	// Internationalization hook
	const { t } = useTranslation(appDefinition.locales);

	// Create and load the app slice in the store
	const store = useStore();

	// Load the store hook
	const dispatch = useDispatch();

	// Get the screen height
	const screenHeight = use100vh();

	// Load the reducer and favicon
	useEffect(() => {
		store.injectReducer(appDefinition.slice, appSlice.reducer);
		document.getElementById("favicon").href = appDefinition.favicon;
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Load data from the store of the App
	const menu = useSelector(appSlice.selectPage);
	const activeDatasets = useSelector(appSlice.selectDatasets);
	const activeRefines = useSelector(appSlice.selectActiveRefines);
	const isActiveFilter = useSelector(appSlice.isRefined);
	const loadDataStatus = useSelector(appSlice.selectLoadDataStatus);
	const activePage = useSelector(appSlice.selectLoadDataStatus);

	const clientParameters = useSelector(appSlice.selectClientParameters);

	// Load client parameters as a filter for the indicators
	useEffect(() => {
		dispatch(appSlice.fetchDataset(clientInitParameters));
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activeRefines?.spaceId]);

	// Load client parameters active indicators as an initial filter for the datasets
	// and load the first space as the initial refine for spaceLabel
	useEffect(() => {
		dispatch(appSlice.actions.clear(filterOptions.filter((option) => option.clear)));
		// eslint-disable-next-line prefer-const
		let initialRefines = [
			{ key: "spaceId", value: clientParameters?.spaceId },
			{ key: "type", value: clientParameters?.initRefines?.type }
		];

		dispatch(appSlice.actions.refine(initialRefines));
	},
		[clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Reload window if data expired
	useEffect(() => {
		if (loadDataStatus === "sessionExpired") {
			dispatch(appSlice.actions.reset());
			dispatch(requestSignIn());
			openSaml();
		}
	}, [loadDataStatus]); // eslint-disable-line react-hooks/exhaustive-deps

	// Fetch and refresh data in the store
	useEffect(() => {
		if (activeDatasets === undefined) {
			return;
		}
		dispatch(appSlice.fetchDatasets());
	}, [activeRefines, clientParameters]); // eslint-disable-line react-hooks/exhaustive-deps

	// Go top if menu has changed
	useEffect(() => {
		document?.getElementById("content-scroll")?.scroll({ top: 0, behavior: "smooth" });
	}, [menu, activeRefines?.siteId, activeRefines?.module]); // eslint-disable-line react-hooks/exhaustive-deps

	// Wait for Data to render App
	if (activeDatasets === undefined) {
		return <Loading message="Wait a moment while we load your app." />;
	}

	/* 	// Refine on module if there's only one
	if (activeDatasets?.listModules?.data?.length === 1 && activeRefines.module === undefined) {
		dispatch(appSlice.actions.refine([{ key: "module", value: activeDatasets?.listModules?.data[0].module }]));
	} */

	// Load module picto if any
	const ModuleMaterialUIIcon = moduleOptions[activeRefines.module]?.picto ?? ErrorOutlineIcon;

	return (
		<div className="relative h-full w-full">
			<IF condition={loadDataStatus === "loading"}>
				<Loading />
			</IF>

			<div className="relative flex justify-between max-w-screen-3xl mx-auto h-full bg-white" >
				<SideBar
					appSlice={appSlice}
					appDefinition={appDefinition}
					menuOptions={menuOptions}
					menu={menu}
					noTitle
					colorMenu="#0092D1"
					colorAccent="#181F3E"
					locales={undefined}
				/>
				<div className="flex flex-col flex-grow md:pl-24 h-full">

					<header className="relative hidden lg:flex items-center justify-between w-full bg-white z-20 md:grid-cols-6">
						<div className="flex items-center gap-x-6 ml-6 h-20 my-2">
							<div className="flex flex-shrink-0 items-center justify-center z-20
                                bg-white w-28 h-16 text-sm text-black rounded-lg font-semibold ">
								{clientParameters?.logo ?
									<img src={`/images/CompanyLogos/${clientParameters?.logo}`}
										className="h-10 object-contain" alt={`${clientParameters?.client} logo`} />
									: <span><BusinessIcon fontSize="small" className="pr-1" />{clientParameters?.projectLabel}</span>}
							</div>

							<div className="absolute w-112 h-24 overflow-hidden" >
								<img onError={(ev) => ev.target.src = "/images/SupplyR/Markets/default.jpg"}
									src={"/images/ChargeScan/ChargeScanBg.png"} alt="Market Img"
									className="absolute left-32 top-0 w-72 h-36 rounded-b-full" style={{ zIndex: -1 }} />
							</div>

							<div className="hidden md:flex flex-col justify-center ml-44 space-y-2 w-full z-20">

								{/* main filters */}
								<div className="flex flex-grow items-center gap-x-2 ">

									<div className="h-10 w-48">
										<SwitchSelector
											onChange={(value) => {
												dispatch(appSlice.actions.clear(filterOptions.filter((option) => option.clear)));
												dispatch(appSlice.actions.refine([{ key: "type", value }]));
											}}
											options={
												[
													{
														label: "Construction",
														value: "construction",
														selectedBackgroundColor: "#fff",
														selectedfontcolor: "#0092D1"
													},
													{
														label: "Operation",
														value: "operation",
														selectedBackgroundColor: "#fff",
														selectedfontcolor: "#0092D1"
													}
												]
											}
											initialSelectedIndex={clientParameters?.initRefines?.type}
											backgroundColor={"#eee"}
											selectedfontcolor={"#0092D1"}
											selectionIndicatorMargin={3}
											wrapperBorderRadius={14}
											disabled={activeDatasets?.listTypes?.data?.length < 2}
										/>
									</div>

									{filterOptions.filter((option) => !option.manualPosition).map((options, i) => (
										<FilterMenu
											key={i}
											appSlice={appSlice}
											options={options}
											cleanStyle
											menuSelectedColor="#0092D1"
											displayNb
											manualRenderOption
											clearOnChange={filterOptions.filter((option) => option.clear)}
											className="z-3xl"
											locales={appDefinition.locales}
										/>
									))}
									<div className="self-end ml-2">
										<ClearButton appSlice={appSlice} isActiveFilter={isActiveFilter}
											options={[...filterOptions.filter((option) => option.clear), ...additionalFilters]}
											locales={appDefinition.locales} />
									</div>

								</div>
							</div>
						</div>
					</header>

					<div id="content-scroll" className="flex-grow bg-chargescan_primary-bg overflow-y-auto
                     px-5 pt-5 md:px-8 md:pt-10 lg:pt-12 2xl:px-12"
						style={{ height: `calc(${screenHeight}px - 10rem)` }}>

						<IF condition={menu === "HOME"}>
							<div className="grid grid-cols-2 grid-flow-row gap-y-4 gap-x-4 md:grid-cols-6 md:gap-x-8 md:gap-y-8 pb-24 md:pb-10">
								<div className="col-span-2 md:col-span-6">
									<p className="text-4xl md:text-5xl font-extrabold uppercase mb-1">
										<IF condition={activeRefines.module === undefined || activeDatasets.listModules?.data?.length === 1}>
											<Trans t={t} i18nKey="homePage.header.headline">
												{t("homePage.header.headline1")}
												<span className="text-chargescan_primary-default ml-2">
													{t("homePage.header.headline2")}
												</span>
											</Trans>
										</IF>
										<IF condition={activeRefines.module !== undefined && activeDatasets.listModules?.data?.length !== 1}>
											{t(`modules.${moduleOptions[activeRefines.module]?.locale}.title`)}&nbsp;
											<span className="text-chargescan_primary-default">
												{t("homePage.module.headline")}
											</span>

										</IF>
									</p>

									<p className="text-sm md:text-lg font-light leading-6">
										<IF condition={activeRefines.module === undefined}>
											{t("homePage.header.introduction")}
										</IF>
										<IF condition={activeRefines.module !== undefined}>
											{t("homePage.module.introduction")}
										</IF>
									</p>
								</div>

								{/* home page progressive bar info */}
								<IF condition={activeRefines.module === undefined}>

									<div className={`flex flex-col justify-center alphaBlur gap-y-4 rounded-xl px-4
													py-2 items-center col-span-full max-h-52 md:max-h-42 pb-8 md:pb-10
													lg:col-span-4 ${activeRefines.label ? "xl:col-span-4" : "xl:col-span-3"}`}>
										{activeRefines?.label
											? <div className="flex flex-row justify-between w-full ">
												<div className="flex flex-row">
													<div className="icon">
														<RoomIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
													</div>
													<div className="flex flex-col">
														<p className="text-xs md:text-lg font-semibold">
															{activeDatasets?.sites?.data?.[0]?.label}
														</p>
														<p className="text-xs">
															{activeDatasets?.sites?.data?.[0]?.city} {activeDatasets?.sites?.data?.[0]?.country}
														</p>
														<p className="text-xs">
															<Moment format="YYYY/MM/DD">{activeDatasets?.sites?.data?.[0]?.maxPublicationDate}</Moment>
														</p>
													</div>
												</div>
												<div className="space-holder"></div>
												<div>
													<div className="flex flex-row">
														<p className="hidden md:block md:text-xs lg:text-base mr-1">
															{t("homePage.progress.getAccess")}
															<ArrowRightIcon className="text-chargescan_primary-default" />
														</p>
														<div className="border-4 bg-white rounded-full p-1.5 -mr-6 -mt-5">
															<a
																className="flex-shrink-0"
																rel="noreferrer"
																href={`https://maiaplus.bureauveritas.com/browse/
																${activeDatasets?.sites?.data?.[0]?.project}/
																${activeDatasets?.sites?.data?.[0]?.spaceId}/SITE/
																${activeDatasets?.sites?.data?.[0]?.siteId}`}
																target="_blank"
															>
																<DescriptionIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
															</a>
														</div>
													</div>
													<div></div>
												</div>
											</div>
											: <div className="flex items-center gap-x-2 w-full">
												<SettingsInputSvideoIcon
													className="text-chargescan_primary-default md:text-4xl" />
												<p className="text-small md:text-2xl font-semibold">
													{t("homePage.scope.headline")}&nbsp;
													<span className="text-chargescan_primary-default">
														{t(`homePage.scope.${activeRefines.type}`)}
													</span>
												</p>
											</div>}

										<ParentSize debounceTime={10}>
											{(parent) => {
												const numerator1 = (activeDatasets.chargingStationData?.data?.length ?? 0 + 1);
												const numerator2 = (activeDatasets.chargingStationData?.data
													?.reduce((prevValue, curValue) => (curValue.totalNC === 0 ? 1 : 0) + prevValue, 0));

												const res = ((numerator1 - numerator2) / numerator1) * 100;

												return <>
													<div className="flex flex-col justify-end gap-y-4 h-full w-full">

														<ProgressBarFlat
															strokeColor="#BD0338"
															fillColor="#0092D1"
															duration="1s"
															endPoint={res}
															loadStatus={loadDataStatus}
															height={20}
															width={parent.width}
														/>

														<div className="flex justify-between items-center text-xs md:text-medium font-semibold">
															<p
																className="cursor-pointer"
																onClick={() => {
																	if (activePage !== "NCs") dispatch(appSlice.actions.setPage("NCs"));
																}}>
																{numerator1 - numerator2}
																&nbsp;
																{t("homePage.progress.nonCompliantStation",
																	{ count: (numerator1 - numerator2) })}
															</p>
															<p>{numerator2}
																&nbsp;{t("homePage.progress.compliantStation", { count: numerator2 })}</p>
														</div>

													</div>
												</>;
											}}
										</ParentSize>
									</div>

									{/* home page scope */}
									{activeRefines?.label === undefined &&
										<div className="hidden xl:col-auto xl:flex flex-col alphaBlur rounded-xl h-full px-3 py-1 max-h-36">
											<p className="font-semibold">{t("homePage.scope.scope")}</p>
											<div className="relative flex flex-row gap-x-4 justify-center md:items-center h-full">
												<div className="w-12">
													<ChargingStationPicto color="#0092D1" />
												</div>
												<div>
													<p className="text-xl md:text-4xl font-black text-chargescan_primary-dark leading-6 pt-1">
														{activeDatasets.scope.data?.[0]?.nbSites}
													</p>
													<p className="text-small md:text-xl">
														{t("homePage.scope.site", { count: activeDatasets.scope.data?.[0]?.nbSites })}
													</p>
												</div>
											</div>
										</div>}
								</IF>

								<IF condition={activeRefines.module !== undefined}>

									<div className={`flex flex-col justify-center alphaBlur gap-y-4 rounded-xl px-4
													py-2 items-center col-span-full max-h-52 md:max-h-42 pb-8 md:pb-10
													lg:col-span-4 ${activeRefines.module || activeRefines.label ? "xl:col-span-4" : "xl:col-span-3"}`}>

										{/* Module Progress Bar */}
										<IF condition={activeRefines.label === undefined}>
											<div className="flex justify-between items-center w-full">
												<div className="flex gap-x-2">
													<ModuleMaterialUIIcon
														className="text-chargescan_primary-default text-2xl md:text-4xl" />
													<div className="flex flex-col">
														<p className="font-semibold text-sm sm:text-base md:text-xl">
															{t(`modules.${moduleOptions[activeRefines.module]?.locale}.title`)}
															&nbsp;{t("modules.module")}
														</p>
														<p className="font-light text-xs sm:text-sm md:text-base line-clamp-2">
															{t(`modules.${moduleOptions[activeRefines.module]?.locale}.description`)}
														</p>
													</div>
												</div>
												<div className="hidden md:flex flex-col justify-between items-center md:text-base">
													{t("modules.under")} {t(activeRefines.type)}
												</div>
											</div>
											<div className="h-full w-full">
												<ParentSize debounceTime={10}>
													{(parent) => {
														const numerator1 = (activeDatasets.moduleScores?.data[0]?.nbAssignements);
														const numerator2 = (activeDatasets.moduleScores?.data[0]?.nbCompliant);

														const res = ((numerator1 - numerator2) / numerator1) * 100;

														return <ProgressBarFlat
															strokeColor="#BD0338"
															fillColor="#0092D1"
															duration="1s"
															endPoint={res}
															loadStatus={loadDataStatus}
															height={20}
															width={parent.width}
														/>;
													}}
												</ParentSize>
												<div className="flex justify-between items-center text-xs md:text-medium font-semibold">
													<p
														className="cursor-pointer"
														onClick={() => {
															if (activePage !== "NCs") dispatch(appSlice.actions.setPage("NCs"));
														}}>
														{activeDatasets.scope.data?.[0]?.nbNotCompliant}
														&nbsp;{t("homePage.progress.nonCompliantSite",
															{ count: activeDatasets.scope.data?.[0]?.nbNotCompliant })}
													</p>
													<p>{activeDatasets.scope.data?.[0]?.nbCompliant}
														&nbsp;{t("homePage.progress.compliantSite",
															{ count: activeDatasets.scope.data?.[0]?.nbCompliant })}</p>
												</div>
											</div>
										</IF>

										{/* Site Progress Bar */}
										<IF condition={activeRefines.label !== undefined}>
											<div className="flex justify-between items-center w-full">
												<div className="flex gap-x-2">
													<div className="flex flex-row">
														<div className="icon">
															<RoomIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
														</div>
														<div className="flex flex-col">
															<p className="text-xs md:text-lg font-semibold">
																{activeDatasets?.sites?.data?.[0]?.label}
															</p>
															<p className="text-xs">
																{activeDatasets?.sites?.data?.[0]?.city} {activeDatasets?.sites?.data?.[0]?.country}
															</p>
															<p className="text-xs">
																<Moment format="YYYY/MM/DD">
																	{activeDatasets?.sites?.data?.[0]?.maxPublicationDate}
																</Moment>
															</p>
														</div>
													</div>
												</div>
												<div className="hidden md:flex flex-col justify-between items-center md:text-base">
													{t("modules.under")} {t(activeRefines.type)}
												</div>
											</div>
											<ParentSize debounceTime={10}>
												{(parent) => {
													const numerator1 = (activeDatasets.chargingStationData?.data?.length ?? 0 + 1);
													const numerator2 = (activeDatasets.chargingStationData?.data
														?.reduce((prevValue, curValue) => (curValue.totalNC === 0 ? 1 : 0) + prevValue, 0));

													const res = ((numerator1 - numerator2) / numerator1) * 100;

													return <>
														<div className="flex flex-col justify-end gap-y-4 h-full w-full">

															<ProgressBarFlat
																strokeColor="#BD0338"
																fillColor="#0092D1"
																duration="1s"
																endPoint={res}
																loadStatus={loadDataStatus}
																height={20}
																width={parent.width}
															/>

															<div className="flex justify-between items-center text-xs md:text-medium font-semibold">
																<p
																	className="cursor-pointer"
																	onClick={() => {
																		if (activePage !== "NCs") dispatch(appSlice.actions.setPage("NCs"));
																	}}>
																	{numerator1 - numerator2}
																	&nbsp;
																	{t("homePage.progress.nonCompliantStation",
																		{ count: (numerator1 - numerator2) })}
																</p>
																<p>{numerator2}
																	&nbsp;{t("homePage.progress.compliantStation", { count: numerator2 })}</p>
															</div>

														</div>
													</>;
												}}
											</ParentSize>
										</IF>

									</div>
								</IF>

								{/* home page map */}
								<div className="lg:-mt-28 hidden md:flex row-span-3 col-span-full lg:col-span-2">
									<ParentSize debounceTime={10}>
										{(parent) => (
											<div className="bg-white overflow-hidden rounded-xl"
											// style={{ width: `calc(${ window.innerWidth}px - 3rem)` }}
											>
												<MapLeaflet
													appSlice={appSlice}
													dataset="sites"
													scoreAccessor={(d) => d.status}
													fieldsTooltip={chargeScanMapTooltip}
													CustomMarker={ChargeScanMapMarker}
													mapType="jawgTerrain"
													minZoom={2}
													getMarkerColor={getMarkerColor}
													mapHeight={window.innerWidth > 768
														? (0.8 * parent.width)
														: (0.5 * window.innerWidth > 200
															? 0.5 * window.innerWidth
															: 200)}
													locales={appDefinition.locales}
												/>
											</div>
										)}
									</ParentSize>
								</div>

								<div className={` flex flex-col w-full col-span-full 
								${activeRefines.module || activeRefines.label ? "lg:col-span-6 lg:-mt-18 " : "lg:col-span-4"}`}>
									<p className="text-xl font-semibold">
										{t("homePage.detailedStatus.title")}
									</p>
									<p className="text-sm font-light leading-6">
										{t("homePage.detailedStatus.description")}
									</p>
								</div>

								<IF condition={activeRefines.module === undefined && activeRefines.label === undefined}>

									{activeDatasets?.moduleScores?.data?.map((module, i) => {
										const MaterialUIIcon = moduleOptions[module.module]?.picto ?? ErrorOutlineIcon;
										return <div key={`module ${i}`}
											className="col-span-1 md:col-span-2 lg:col-span-1
											flex flex-col alphaBlur rounded-xl py-3 w-full cursor-pointer"
											onClick={() => {
												dispatch(appSlice.actions.refine([{ key: "module", value: module.module }]));
											}}>
											<div className="flex-shrink-0 md:h-8 lg:h-12 xl:h-16 xl:mb-8 ">
												<div className="flex items-start justify-between px-2 font-semibold leading-5 h-8 md:h-10">
													<p className="text-sm sm:text-base md:text-xl lg:text-base xl:text-lg">
														{t(`modules.${moduleOptions[module.module].locale}.title`)}
													</p>
													<MaterialUIIcon className="text-xl md:hidden xl:text-3xl " />
												</div>
												<div className="text-xs md:hidden xl:block xl:text-medium font-light px-2 line-clamp-2">
													{t(`modules.${moduleOptions[module.module].locale}.description`)}
												</div>
											</div>
											<ChargeScanArcChart
												appDefinition={appDefinition}
												percentage={((module?.moduleScore ?? 0) * 100)?.toFixed(0)}
												className="self-start pr-2"
												sitesNb={module?.nbAssignements}
												compliantNb={module?.nbCompliant}
												theme="chargeScan"
											/>
										</div>;
									})}
								</IF>

								<IF condition={activeRefines.label !== undefined}>
									<div className="lg:-mt-10 col-span-2 md:col-span-6 bg-transparent">
										<div className="w-full alphaBlur rounded-xl">
											<ChargeScanStationTable
												appSlice={appSlice}
												locales={appDefinition.locales}
											/>
										</div>
									</div>
								</IF>

								<IF condition={(activeRefines.module !== undefined)}>
									<div className={`col-span-2 md:col-span-6 alphaBlur rounded-xl ${activeRefines.label !== undefined
										? "" : "lg:-mt-10"}`}>
										<SectionsTable
											appSlice={appSlice}
											structureSections={sectionStructure.slice(0, clientParameters?.sectionLevels)}
											dataset="sectionAlertList"
											locales={appDefinition.locales}
										/>
									</div>
								</IF>

							</div>
						</IF>

						<IF condition={menu === "MAP"}>
							<div className="flex flex-col gap-y-8 gap-x-4 md:gap-x-8 md:gap-y-10 lg:gap-y-16 xl:gap-y-10 2xl:gap-y-10">
								<div className="hidden md:block">
									<p className="text-5xl font-extrabold uppercase mb-1">

										{t("mapPage.header.headline1")}&nbsp;
										<span className="text-chargescan_primary-default">
											{t("mapPage.header.headline2")}
										</span>

									</p>
									<p className="text-lg w-2/3 leading-6">
										{t("mapPage.header.introduction")}
									</p>
								</div>

								<div className="hidden md:flex flex-col justify-center alphaBlur
                                        gap-y-4 rounded-xl px-4 py-4 items-center w-full max-h-36">

									{activeRefines?.label
										? <div className="flex flex-row justify-between w-full">
											<div className="flex flex-row">
												<div className="icon">
													<RoomIcon className="text-7xl text-chargescan_primary-default" />
												</div>
												<div className="flex flex-col">
													<p className="text-lg font-semibold">
														{activeDatasets?.sites?.data?.[0]?.label}
													</p>
													<p className="text-sm">
														{activeDatasets?.sites?.data?.[0]?.city} {activeDatasets?.sites?.data?.[0]?.country}
													</p>
													<p className="text-sm">
														<Moment format="YYYY/MM/DD">{activeDatasets?.sites?.data?.[0]?.maxPublicationDate}</Moment>
													</p>
												</div>
											</div>
											<div className="space-holder"></div>
											<div>
												<div className="flex flex-row">
													<p className="text-sm mr-1">
														{t("homePage.progress.getAccess")}
														<ArrowRightIcon className="text-chargescan_primary-default" />
													</p>
													<div className="border-4 bg-white rounded-full p-1.5 -mr-6 -mt-5">
														<DescriptionIcon className="text-7xl text-chargescan_primary-default" />
													</div>
												</div>
												<div></div>
											</div>
										</div>
										: <div className="flex items-center gap-x-2 w-full">
											<SettingsInputSvideoIcon
												className="text-chargescan_primary-default  md:text-4xl" />
											<p className="text-small md:text-2xl font-semibold">
												{t("homePage.scope.headline")}&nbsp;
												<span className="text-chargescan_primary-default">
													{t(`homePage.scope.${activeRefines.type}`)}
												</span>
											</p>
										</div>}

									<div className="flex flex-col w-full">
										<ParentSize debounceTime={10}>
											{(parent) => {
												const numerator = activeDatasets.scope?.data[0]?.nbNotCompliant;
												const denominator = activeDatasets.scope?.data[0]?.nbSites;

												const res = (numerator / denominator) * 100;

												return <ProgressBarFlat
													strokeColor="#BD0338"
													fillColor="#0092D1"
													duration="1s"
													endPoint={res}
													loadStatus={loadDataStatus}
													height={20}
													width={parent.width}
												/>;
											}}
										</ParentSize>
										<div className="flex justify-between items-center text-medium font-semibold">
											<p
												className="cursor-pointer"
												onClick={() => {
													if (activePage !== "NCs") dispatch(appSlice.actions.setPage("NCs"));
												}}>
												{activeDatasets.scope.data?.[0]?.nbNotCompliant} {t("homePage.progress.nonCompliantSite",
													{ count: activeDatasets.scope.data?.[0]?.nbNotCompliant })}
											</p>
											<p>{activeDatasets.scope.data?.[0]?.nbCompliant} {t("homePage.progress.compliantSite",
												{ count: activeDatasets.scope.data?.[0]?.nbCompliant })}</p>
										</div>
									</div>
								</div>

								<div className="col-span-full md:mt-9 lg:mt-0 rounded-xl row-span-1">
									<ParentSize debounceTime={10} className="chargeScanMap"
										style={{ height: `calc(${window.innerHeight}px - 29rem)` }}
									>
										{(parent) => (
											<MapLeaflet
												appSlice={appSlice}
												dataset="sites"
												scoreAccessor={(d) => d.status}
												fieldsTooltip={chargeScanMapTooltip}
												CustomMarker={ChargeScanMapMarker}
												mapType="jawgTerrain"
												minZoom={2}
												getMarkerColor={getMarkerColor}
												mapHeight={window.innerWidth > 767 ? parent.height : window.innerHeight - 100}
												locales={appDefinition.locales}
											/>
										)}
									</ParentSize>
								</div>
							</div>
						</IF>

						<IF condition={menu === "DISTRIB"}>
							<div className="grid grid-cols-2 gap-y-4 gap-x-4 md:grid-cols-6 md:gap-x-8 md:gap-y-8 lg:gap-y-8 xl:gap-y-10 2xl:gap-y-10">
								<div className="col-span-2 md:col-span-6">
									<p className="text-4xl md:text-5xl font-extrabold uppercase mb-1">

										<span className="text-chargescan_primary-default">
											{t("distribPage.header.headline")}
										</span>

									</p>
									<p className="text-sm md:text-lg font-light leading-6">
										{t("distribPage.header.introduction")}
									</p>
								</div>

								<div className="flex flex-col justify-center alphaBlur col-span-full
                                        gap-y-4 rounded-xl px-4 py-4 items-center w-full max-h-36"
								>

									{activeRefines?.label
										? <div className="flex flex-row justify-between w-full ">
											<div className="flex flex-row">
												<div className="icon">
													<RoomIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
												</div>
												<div className="flex flex-col">
													<p className="text-xs md:text-lg font-semibold">
														{activeDatasets?.sites?.data?.[0]?.label}
													</p>
													<p className="text-xs">
														{activeDatasets?.sites?.data?.[0]?.city} {activeDatasets?.sites?.data?.[0]?.country}
													</p>
													<p className="text-xs">
														<Moment format="YYYY/MM/DD">{activeDatasets?.sites?.data?.[0]?.maxPublicationDate}</Moment>
													</p>
												</div>
											</div>
											<div className="space-holder"></div>
											<div>
												<div className="flex flex-row">
													<p className="hidden md:block md:text-xs lg:text-base mr-1">
														{t("homePage.progress.getAccess")}
														<ArrowRightIcon className="text-chargescan_primary-default" />
													</p>
													<div className="border-4 bg-white rounded-full p-1.5 -mr-6 -mt-5">
														<a
															className="flex-shrink-0"
															rel="noreferrer"
															href={`https://maiaplus.bureauveritas.com/browse/
														${activeDatasets?.sites?.data?.[0]?.project}/
														${activeDatasets?.sites?.data?.[0]?.spaceId}/SITE/
														${activeDatasets?.sites?.data?.[0]?.siteId}`}
															target="_blank"
														>
															<DescriptionIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
														</a>
													</div>
												</div>
												<div></div>
											</div>
										</div>
										: <div className="flex items-center gap-x-2 w-full">
											<SettingsInputSvideoIcon
												className="text-chargescan_primary-default  md:text-4xl" />
											<p className="text-small md:text-2xl font-semibold">
												{t("homePage.scope.headline")}&nbsp;
												<span className="text-chargescan_primary-default">
													{t(`homePage.scope.${activeRefines.type}`)}
												</span>
											</p>
										</div>}

									<div className="flex flex-col w-full">
										<ParentSize debounceTime={10}>
											{(parent) => {
												const numerator = activeDatasets.scope?.data[0]?.nbNotCompliant;
												const denominator = activeDatasets.scope?.data[0]?.nbSites;

												const res = (numerator / denominator) * 100;
												return <ProgressBarFlat
													strokeColor="#BD0338"
													fillColor="#0092D1"
													duration="1s"
													endPoint={res}
													loadStatus={loadDataStatus}
													height={20}
													width={parent.width}
												/>;
											}}
										</ParentSize>
										<div className="flex justify-between items-center text-medium font-semibold">
											<p
												className="cursor-pointer"
												onClick={() => {
													if (activePage !== "NCs") dispatch(appSlice.actions.setPage("NCs"));
												}}>
												{activeDatasets.scope.data?.[0]?.nbNotCompliant} {t("homePage.progress.nonCompliantSite",
													{ count: activeDatasets.scope.data?.[0]?.nbNotCompliant })}
											</p>
											<p>{activeDatasets.scope.data?.[0]?.nbCompliant} {t("homePage.progress.compliantSite",
												{ count: activeDatasets.scope.data?.[0]?.nbCompliant })}</p>
										</div>
									</div>
								</div>

								<div className="flex flex-col col-span-full rounded-xl" style={{ height: "calc(120%)" }}>
									<div className="w-full">
										<TitleCard titleLocal="distribPage.chart.title" subTitleLocal="distribPage.chart.subTitle"
											infoLocale="" small locales={appDefinition.locales} />
									</div>
									<div className="flex flex-col rounded-xl alphaBlur w-full px-6 pt-8 pb-2">
										<ParentSize>
											{(parent) => (
												<ColumnGraph
													appSlice={appSlice}
													dataset={"sites"}
													color={"#1876bd"}
													strokeWidth={8}
													width={parent.width}
													getScore={(d) => d.badAnswersCounter}
													getModule={(d) => d.description}
													filterData={(d) => d.badAnswersCounter !== 0}
													sortScoreDimension
													scoreScaleMax
													refineKeys={["label"]}
													locales={appDefinition.locales}
												/>
											)}
										</ParentSize>
									</div>
								</div>

							</div>
						</IF>

						<IF condition={menu === "NCs"}>

							<div className="grid grid-cols-2 gap-y-4 gap-x-4 md:grid-cols-6 md:gap-x-8 md:gap-y-8">
								<div className="col-span-2 md:col-span-6">
									<p className="text-xl sm:text-3xl md:text-5xl font-extrabold uppercase mb-1">

										{t("NCs.header.headline1")}&nbsp;
										<span className="text-chargescan_primary-default">
											{t("NCs.header.headline2")}
										</span>

									</p>
									<p className="text-sm md:text-lg font-light leading-6">
										{t("NCs.header.introduction")}
									</p>
								</div>

								<div className="flex flex-col justify-center alphaBlur col-span-full
                                        gap-y-4 rounded-xl px-4 py-4 items-center w-full max-h-36">

									{activeRefines?.label
										? <div className="flex flex-row justify-between w-full ">
											<div className="flex flex-row">
												<div className="icon">
													<RoomIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
												</div>
												<div className="flex flex-col">
													<p className="text-xs md:text-lg font-semibold">
														{activeDatasets?.sites?.data?.[0]?.label}
													</p>
													<p className="text-xs">
														{activeDatasets?.sites?.data?.[0]?.city} {activeDatasets?.sites?.data?.[0]?.country}
													</p>
													<p className="text-xs">
														<Moment format="YYYY/MM/DD">{activeDatasets?.sites?.data?.[0]?.maxPublicationDate}</Moment>
													</p>
												</div>
											</div>
											<div className="space-holder"></div>
											<div>
												<div className="flex flex-row">
													<p className="hidden md:block md:text-xs lg:text-base mr-1">
														{t("homePage.progress.getAccess")}
														<ArrowRightIcon className="text-chargescan_primary-default" />
													</p>
													<div className="border-4 bg-white rounded-full p-1.5 -mr-6 -mt-5">
														<a
															className="flex-shrink-0"
															rel="noreferrer"
															href={`https://maiaplus.bureauveritas.com/browse/
														${activeDatasets?.sites?.data?.[0]?.project}/
														${activeDatasets?.sites?.data?.[0]?.spaceId}/SITE/
														${activeDatasets?.sites?.data?.[0]?.siteId}`}
															target="_blank"
														>
															<DescriptionIcon className="text-4xl md:text-7xl text-chargescan_primary-default" />
														</a>
													</div>
												</div>
												<div></div>
											</div>
										</div>
										: <div className="flex items-center gap-x-2 w-full">
											<SettingsInputSvideoIcon
												className="text-chargescan_primary-default  md:text-4xl" />
											<p className="text-small md:text-2xl font-semibold">
												{t("homePage.scope.headline")}&nbsp;
												<span className="text-chargescan_primary-default">
													{t(`homePage.scope.${activeRefines.type}`)}
												</span>
											</p>
										</div>}

									<div className="flex flex-col w-full">
										<ParentSize debounceTime={10}>
											{(parent) => (
												<ProgressBarFlat
													strokeColor="#BD0338"
													fillColor="#0092D1"
													duration="1s"
													// eslint-disable-next-line no-unsafe-optional-chaining
													endPoint={((activeDatasets.scope.data?.[0]?.nbNotCompliant
														// eslint-disable-next-line no-unsafe-optional-chaining
														/ activeDatasets.scope.data?.[0]?.nbSites) * 100).toFixed(0)}
													loadStatus={loadDataStatus}
													height={20}
													width={parent.width}
												/>
											)}
										</ParentSize>
										<div className="flex justify-between items-center text-medium font-semibold">
											<p>{activeDatasets.scope.data?.[0]?.nbNotCompliant} {t("homePage.progress.nonCompliantSite",
												{ count: activeDatasets.scope.data?.[0]?.nbNotCompliant })}</p>
											<p>{activeDatasets.scope.data?.[0]?.nbCompliant} {t("homePage.progress.compliantSite",
												{ count: activeDatasets.scope.data?.[0]?.nbCompliant })}</p>
										</div>
									</div>
								</div>

								<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 col-span-full rounded-xl">
									{activeDatasets?.listNonConformities?.data.map((issue, i) => (
										<ChargeScanNcStatus key={`issue${i}`} issue={issue} appSlice={appSlice} />
									))}
								</div>
							</div>
						</IF>

						<IF condition={menu === "INFO"}>
							<div className="w-full rounded-xl overflow-hidden ">
								<img src="/images/ChargeScan/ChargeScanInfographics.png"
									alt="ChargeScan Infographics"
									className="w-full object-contain" />
							</div>
						</IF>

					</div>

				</div>

				{/*
				* FILTER SECTION OF THE CHARGESCAN PAGE
				*/}
				<HorizontalFilters appSlice={appSlice} filterOptions={filterOptions}
					customStyle={customStylesChargeScan} customStyleSearch={customStylesSearchChargeScan} initRefines={clientParameters?.initRefines}
					classTitle="chargescan_primary-default" siteMenuLabel="Sites" isActiveFilter={isActiveFilter}
					locales={appDefinition.locales} hideIconScreenSize="lg">
					<ChargeScanMobileFilterHeader appSlice={appSlice} />
				</HorizontalFilters>

			</div>
		</div>
	);
}

export default ChargeScan;
