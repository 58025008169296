import PropTypes from "prop-types";

function Loading({ message = undefined, isComponent = false }) {
	return (
		<>
			{isComponent ?
				<div className="flex w-full h-full justify-center items-center m-auto z-2xl">
					<div className="w-16">
						<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
							viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
							<circle fill="#eee" stroke="none" cx="6" cy="50" r="6">
								<animate
									attributeName="opacity"
									dur="1s"
									values="0;1;0"
									repeatCount="indefinite"
									begin="0.1" />
							</circle>
							<circle fill="#eee" stroke="none" cx="26" cy="50" r="6">
								<animate
									attributeName="opacity"
									dur="1s"
									values="0;1;0"
									repeatCount="indefinite"
									begin="0.2" />
							</circle>
							<circle fill="#eee" stroke="none" cx="46" cy="50" r="6">
								<animate
									attributeName="opacity"
									dur="1s"
									values="0;1;0"
									repeatCount="indefinite"
									begin="0.3" />
							</circle>
						</svg>
					</div>
				</div>
				: <div className="absolute flex flex-col flex-grow w-full h-full justify-center items-center m-auto z-2xl">
					{message && <p className=" text-gray-700">{message}</p>}
					<img src="/images/Alpha/gooeyspinner.svg" className="-my-14" />
				</div>
			}
		</>
	);
}

Loading.propTypes = {
	message: PropTypes.string,
	isComponent: PropTypes.bool
};

export default Loading;
